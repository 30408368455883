define("ember-intl/translations", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = [["de-de", {
    "AccountModal": {
      "Change": "Ändern",
      "Head1": "Konto Bearbeiten",
      "Head2": "Profilbild Bearbeiten",
      "Label1": "Firma",
      "Label2": "Account*",
      "Login": "Anmelden",
      "LogoutMessage": "Sie wurden abgemeldet. Hier können sie sich erneut anmelden:",
      "MainAcc": {
        "CreateAccount": "Account erstellen",
        "Error": "Error",
        "ForgotPassword": "Passwort vergessen?",
        "Head1": "Anmelden",
        "ResendPassword": "Wir haben Ihnen eine E-Mail zugesant, welche Instruktionen enthält, wie Sie Ihr Passwort zurücksetzen können.",
        "Success": "Erfolg"
      },
      "Remove": "Entfernen",
      "RequiredFields": "* Pflichtfelder",
      "Save": "Speichern",
      "UploadImage": "Bild hochladen"
    },
    "BurgerMenu": {
      "AccountInformation": "Kontoinformationen",
      "Company": "Firmenverwaltung",
      "DataProtection": "Datenschutz",
      "HelpAndSupport": {
        "Chat": "Support Chat",
        "FirewallSetting": "Firewall-Einstellungen",
        "Head1": "Hilfe und Support",
        "Mail": "E-Mail: armario@fimab.eu",
        "Tel": "Support Telefon: 07053 96839-65"
      },
      "Imprint": "Impressum",
      "Legal": {
        "DataProtection": "Datenschutz",
        "Header": "Rechtliches",
        "Imprint": "Impressum"
      },
      "Login": "Anmelden",
      "Logout": "Abmelden",
      "OrderHistory": "Bestellübersicht",
      "Support": {
        "FirewallSetting": "ARMARIO Firewall Einstellungen für Unternehmen",
        "Head1": "Support",
        "SuppChat": "Support Chat",
        "SuppMail1": "Support Mailadresse:",
        "SuppMail2": "armario@fimab.eu",
        "SuppTel1": "Support Telefonnummer: ",
        "SuppTel2": "+49 (7053) 96839-65"
      },
      "UserProfil": "Benutzerprofil"
    },
    "Company": {
      "CompanyCard": {
        "Leave": "Verlassen"
      },
      "Employees": {
        "Header": "Mitarbeiter"
      },
      "Header": "Ihre Firma",
      "OpenInvites": {
        "Filter": "Status Filter",
        "FilterOptions": {
          "Accepted": "Akzeptiert",
          "Declined": "Abgelehnt",
          "Open": "Offen"
        },
        "Header": "Offene Einladungen"
      },
      "OpenRequests": {
        "Filter": "Status Filter",
        "FilterOptions": {
          "Accepted": "Akzeptiert",
          "Declined": "Abgelehnt",
          "Open": "Offen"
        },
        "Header": "Offene Anfragen"
      },
      "Table": {
        "Accept": "Akzeptieren",
        "Actions": "Aktionen",
        "Cancel": "Abbrechen",
        "Created": "Erstellt am",
        "Date": "Eingeladen am",
        "Decline": "Ablehnen",
        "Department": "Abteilung",
        "EMail": "E-Mail",
        "Empty": "Keine offenen Einladungen",
        "EmptyEmployees": "Keine Mitarbeiter vorhanden",
        "Name": "Name",
        "Origin": "Herkunft",
        "OriginInvite": "Eingeladen",
        "OriginManual": "Manuell Angefragt",
        "OriginSystem": "Vom System erkannt",
        "Status": "Status",
        "StatusAccepted": "Aktzeptiert",
        "StatusDeclined": "Abgelehnt",
        "StatusWait": "Warten auf Bestätigung"
      }
    },
    "Configurations": {
      "AutoSave": {
        "Text1": "Ihre Konfiguration wird automatisch gespeichert",
        "Text2": "Anmelden um die automatische Speicherung zu aktivieren"
      },
      "ModalSelection": {
        "Cabinet": {
          "Klemmkasten": "",
          "Modell 1": "(1-türig)",
          "Modell 1: Anschlag Links": "",
          "Modell 1: Anschlag Rechts": "",
          "Modell 2": "(2-türig)",
          "Modell 3": "(3-türig)"
        },
        "FooterText1": "Wir fertigen alle Schaltschränke auch in",
        "FooterTextSpan": "Edelstahl",
        "Main1": "Geben Sie hier die Dimensionen Ihres Schaltschrankes an.",
        "Main2": "Wählen Sie Ihren passenden Schaltschranktyp",
        "SelectButton": "Auswählen"
      },
      "ModelBox": {
        "Main1": "Gewähltes Modell"
      },
      "OptionBox": {
        "InfoText1": "Achtung! Ausführung in Edelstahl nur auf Anfrage möglich.",
        "Label1": "Schaltschrank Dach",
        "Label2": "Schaltschrank Sockel",
        "Label2Height": "Sockelhöhe",
        "Label3": "Innenliegende Scharniere",
        "Main1": "Optionen",
        "OkButton": "Ok",
        "WarningBoxHeadline": "Achtung",
        "WarningBoxText1": "Vielen dank für Ihre Interesse. Mit Ihrer Auswahl haben sie dazu beigetragen diese Features weiter zu entwickeln!",
        "WarningBoxText2": "Die ausgewählten Features werden zur Zeit noch nicht eingebaut."
      },
      "SelectedModal": {
        "Dimensions": "Maße der Montageplatte",
        "DimensionsHeight": "Höhe:",
        "DimensionsWidth": "Breite:",
        "Label1": "Interne Bezeichnung",
        "Label2": "Breite",
        "Label3": "Höhe",
        "Label4": "Tiefe",
        "Main1": "Abmessungen",
        "NoMountingPlate": "Dieses Modell beinhaltet keine Montageplatte"
      }
    },
    "Dashboard": {
      "Auth": {
        "ContinueConfiguration": {
          "ConfigColor": "Farbe:",
          "ConfigMaterial": "Material:",
          "ConfigName": "Name:",
          "ContinueButton": "Weiter Konfigurieren",
          "Header1": "Weiter Konfigurieren",
          "Sub1": "Ihren zuletzt konfigurieten Schlatschrank weiter Konfigurieren"
        },
        "LastConfigurations": {
          "ConfigAction": "Aktionen",
          "ConfigModel": "Model",
          "ConfigName1": "Name",
          "ConfigNr1": "Konfig-Nr.",
          "ConfigStatus": "Status",
          "ContinueButton": "Weiter",
          "Header1": "Ihre letzten Konfigurationen",
          "MineConfigsBtn": "Alle Konfigurationen",
          "ViewButton": "Ansehen"
        },
        "OrderConfiguration": {
          "Header1": "Schaltschrank",
          "Header2": "bestellen?",
          "OrderColor": "Farbe:",
          "OrderMaterial": "Material:",
          "OrderName": "Name:",
          "Sub1": "Ihren zuletzt konfigurieten Schlatschrank in wenigen Klicks bestellen",
          "checkButton": "Überprüfen und bestellen"
        },
        "Tipps": {
          "Header1": "Tipps aus der Armario Welt",
          "Link1": "https://www.fimab.eu/schaltschrank-konfigurator/der-schnelle-online-bestellprozess",
          "Link2": "https://www.fimab.eu/schaltschrank-konfigurator/software-fuer-die-schaltschrank-konstruktion",
          "Link3": "https://www.fimab.eu/schaltschrank-konfigurator/edelstahl-ausfuehrungen-bei-allen-schaltschraenken",
          "Link4": "https://www.fimab.eu/schaltschrank-konfigurator/schluss-mit-verstaubten-schaltschraenken",
          "Link5": "https://www.fimab.eu/schaltschrank-konfigurator/schaltschraenke-mit-ableitender-beschichtung-esd",
          "Link6": "https://www.fimab.eu/schaltschrank-konfigurator/nie-wieder-loecher-in-schaltschraenke-bohren",
          "LinkHeader1": "Der schnellste Online Bestellprozess",
          "LinkHeader2": "Konstruktionssoftware für Schaltschränke",
          "LinkHeader3": "Edelstahl Ausführungen bei allen Schaltschränken",
          "LinkHeader4": "Schluss mit verstaubten Schaltschränken",
          "LinkHeader5": "Schaltschränke mit ESD Lackierung",
          "LinkHeader6": "Nie wieder Löcher in Schaltschränke bohren",
          "Tipp1": "Modellauswahl",
          "Tipp2": "Lochbilder gestalten",
          "Tipp3": "Farbe und Material definieren",
          "Tipp4": "Bestellung aufgeben"
        }
      },
      "Header": {
        "CTAAuth": "Neue Konfiguration starten",
        "CTANoAuth": "Anmelden",
        "Main1": "ONLINE",
        "Main2": "SCHALTSCHRANK",
        "Main3": "KONFIGURATOR.",
        "Sub": "ARMARiO STEHT FÜR SCHNELLIGKEIT.",
        "Sub2": "IM PASSGENAUEN INDIVIDUELLEN SCHALTSCHRANKBAU.",
        "Text1": "Danach Bestelleingang. Der Vorteil für Sie: Der Schaltschrank wird komplett nach Ihren Angaben mit allen Bohrungen und Aussparungen geliefert. Sie sparen sich so eine aufwendige Nacharbeitungszeit und können gleich mit der Bestückung Ihrer vorgesehenen Anbaukomponenten beginnen..",
        "Text2": "ARMARiO - Sparen Sie Zeit und Kosten!"
      },
      "NoAuth": {
        "Advantages": {
          "Header1": "IHRE",
          "Header2": "VORTEILE",
          "Header3": "AUF",
          "Header4": "EINEM BLICK."
        },
        "FimabInfo": {
          "BtnText1": "Neue Konfiguration starten",
          "Header1": "FiMAB STEHT FÜR KOMPETENZ.",
          "Header2": "IN DER PRODUKTION VON ZUFRIEDENEN KUNDEN.",
          "ListHeader1": "FiMAB - Wir werden Sie begeistern!",
          "ListPoint1": "32 Jahre Erfahrung im Maschinenbau",
          "ListPoint2": "Familienunternehmen in der 2. Generation",
          "ListPoint3": "Mehr als 700 zufriedene Kunden in Deutschland und Europa",
          "ListPoint4": "Über 500 kundenspezifische Schaltschrank-Varianten ausgeliefert",
          "Main1": "KONFIGURIEREN",
          "Main2": "SIE",
          "Main3": "JETZT",
          "Main4": "IHREN",
          "Main5": "SCHALTSCHRANK",
          "Main6": "AUF",
          "Main7": "ARMARiO",
          "Text1": "Sie kennen das auch: “Gestern noch 20 Schaltschränke auf Lager bestellt und dann, gerade jetzt ändert der Lieferant für Frequenzumformer seine Produktlinie und von jetzt auf nachher passen die Geräte nicht mehr in die Schränke. Gerade mal eben einen vierstelligen Betrag für die Tonne eingelagert.",
          "Text2": "Im Co-Creation Prozess, mit unserem Schaltschrankkonfigurator, gestalten Sie als Nutzer Ihr Produkt genau so, wie Sie es benötigen. Bestellen Sie zum richtigen Zeitpunkt und in der Stückzahl, die Sie jetzt benötigen. Durch die interaktive Prozesskette - Sie gestalten den Wertschöpfungsprozess mit - und die automatisierten Schnittstellen verbinden wir die Vorteile aus zwei Welten. Die Vorteile der Massenfertigung und die der individuellen Einzelfertigung. Mass Customization bedeutet kundenspezifische Serienfertigung.",
          "Text3": "Wir bei FiMAB haben über 30 Jahre Erfahrung in der Produktion von Losgröße 1 für den Sondermaschinenbau. Daraus haben wir eine automatisierte Prozesskette vom Design bis hin zur Auslieferung entwickelt. Wir produzieren Losgröße 1 in Serie zu realistischen Preisen. Keine Überproduktion, keine Lagerhaltung, keine Verschwendung. Ein solcher interaktiver Kunden- Lieferanten-Prozess ist nicht nur ökonomisch, sondern auch ökologisch sinnvoll.",
          "TextBreak": "Unser Geheimtipp für Sie: Gehen Sie mit uns neue Wege und beeindrucken Sie Ihre Kollegen."
        },
        "Information": {
          "InfoStep1": "Zeitersparnis",
          "InfoStep2": "Kostenersparnis",
          "InfoStep3": "Optimale Passgröße",
          "InfoStep4": "Wir fertigen alle Schaltschränke auch in",
          "InfoStep4Bold": "Edelstahl",
          "TextBlock1": "Bestellen Sie bis",
          "TextBlock2": "16:00 Uhr",
          "TextBlock3": "und wir produzieren Ihren Schaltschrank bis zum"
        },
        "ReadyToInstall": {
          "Header1": "EINBAUFERTIGE",
          "Header2": "SCHALTSCHRÄNKE.",
          "StartConfigs": "Neue Konfiguration starten",
          "Text1": "Herzlichen Glückwunsch - die Tatsache, dass Sie sich jetzt die Zeit nehmen und diese Broschüre lesen, macht Sie schon jetzt zu einem Gewinner. Diese wenigen Minuten werden Ihrem Unternehmen nachhaltig Tage der Planung und der Nacharbeit ersparen.",
          "Text2": "Wir haben diese Broschüre zusammengestellt, um Ihnen zu zeigen, wie Sie in wenigen Minuten zu einem perfekten Schaltschrank kommen. Dadurch sparen Sie extrem viel Zeit und erhalten die optimale Lösung für Ihre Verpackungsmaschine. Anhand von Kundenergebnissen, erleben Sie diesen Prozess real und nachvollziehbar."
        },
        "Steps": {
          "Header1": "Variante und Dimension",
          "Header2": "Lochbilder",
          "Header3": "Material und Farbe",
          "Header4": "Bestellung aufgeben",
          "Main1": "NUR",
          "Main2": "4 SCHRITTE",
          "Main3": "ZUM PERFEKTEN",
          "Main4": "SCHALTSCHRANK.",
          "TextBox1": "Wählen Sie unter den Modellen eine passende Variante für Ihren Schaltschrank oder Klemmkasten aus. Definieren Sie die Art der Tür, sowie die Anschlagseite. Geben Sie danach die gewünschten Maße ein. Alle Modelle werden millimetergenau skaliert und passen somit genau in jedes Anlagenmodul.",
          "TextBox2": "Generieren Sie kinderleicht per Drag & Drop der vorgefertigten Anbaukomponenten jedes erdenkliche Lochbild. Der Konfigurator achtet dabei direkt auf die Plausibilität, prüft Kollisionen ab und zeigt diese gegebenenfalls an. Der digitale Zwilling des Schaltschranks sorgt dabei für die Funktionalität und ist Grundlage für eine normgerechte Ausführung.",
          "TextBox3": "Nur ein Klick entscheidet darüber, ob Ihr Schaltschrank in Stahlblech oder Edelstahl gefertigt wird.  Wählen Sie die Farbe und die Oberflächenbeschaffenheit völlig frei nach Ihrem Geschmack, dem CI Ihres Unternehmens oder dem Farbwunsch Ihres Kunden. Jeder Schaltschrank wird direkt in Ihrer Wunschfarbe produziert.",
          "TextBox4": "Nicht nur die Gestaltung, sondern auch die Bestellung können Sie direkt online, 24/7 von jedem Ort der Welt ausführen. Die Daten werden unmittelbar an unsere Produktion übermittelt, was eine kurze Produktionszeit garantiert. Dank des neuartigen B2B-Online-Bestellprozess können Sie auch Ihre Bestellanforderung an einen Kollegen, z. B. im Einkauf weiterleiten.",
          "TextHeader1": "Alle Gehäuse werden millimetergenau skaliert und passen somit in jede Lücke.",
          "TextHeader2": "Nie wieder müssen sie einen Schaltschrank mit der Bohrmaschine nachträglich bearbeiten.",
          "TextHeader3": "Sie sparen sich in jedem Fall den Vorgang des Umlackierens.",
          "TextHeader4": "10 Tage Produktionszeit für ein individuelles Schaltschrankgehäuse sind einmalig in der Branche.",
          "startConfigBtn": "Neue Konfiguration starten"
        },
        "Studies": {
          "ButtonText": "Die ganze Fallstudie lesen.",
          "CollapseHead1": "Ausgangssituation:",
          "CollapseHead2": "Herausforderung:",
          "CollapseHead3": "Die Lösung:",
          "CollapseHead4": "Auf lange Sicht:",
          "CollapseText1": "Hugo Beck Maschinenbau ist Spezialist für horizontale Flowpack-, Folien und Papierverpackungsmaschinen. Von früher war es Hugo Beck gewohnt, Standardschaltschränke zu kaufen und selbst Löcher zu bohren. Zur Vermeidung von aufwendiger Nacharbeit werden bereits interne Standardschränke hergestellt. Leider sind nicht in allen Fällen Standardschränke geeignet, selbst wenn sie nachbearbeitet werden.",
          "CollapseText2": "Der Schaltschrank sollte sehr klein sein. Gleichzeitig muss das Gehäuse aber auch sehr tief sein, da es einen Frequenzumformer in der Steuerung gibt. Hierfür gibt es so gut wie keine Standardlösungen. Abweichungen von den internen Standardgehäusen erfordern immer einen großen Aufwand in der Abstimmung und Herstellung. Es wurde eine Zeit und Kosten schonende Lösung gesucht. Eine aufwendige Nachbereitung sollte vermieden werden. Eine Sonderkonstruktion beim bisherigen Lieferanten erforderte gesonderte Zeichnungen und einen großen Abstimmungsprozess. Dieser Prozess kostet sehr viel Zeit und Geld. Nach vielen Zeichnungen und Experimenten ist Hugo Beck dann auf den ARMARiO aufmerksam geworden.",
          "CollapseText3": "Mit dem Online Konfigurator hat Hugo Beck nun eine Lösung, mit der die Firma die mechanische Konstruktion komplett außen vorlassen kann. Der Leiter der Elektrokonstruktion kann den gesamten Prozess der Gestaltung komplett selbst durchführen. Anschließend werden die Anforderungen mit allen Daten, wie Artikelnummer, Maße, Ausführung und Preis an den Einkauf weitergegeben. Für einen Elektriker, der einen Schrank mit Sondermaßen benötigt, eine sehr schöne Lösung. Zusätzlich sind genau die Komponenten zum Definieren der Lochbilder im System aufgeführt, die Hugo Beck in der täglichen Arbeit braucht. Selbst wenn ein exotisches Lochbild benötigt wird, kann das schnell und unkompliziert mit den Funktionen Rechteck und Kreis selbst erstellt werden. Auch das einfache Ändern von Stahl auf Edelstahl ist sehr komfortabel. Ein flexibles System, das Hugo Beck sehr schnell Resultate liefert. Immer wenn also Sonderlösungen benötigt werden, ist der Online Konfigurator das bevorzugte Tool.",
          "CollapseText4": "Der ARMARiO Schaltschrank ist zwar etwas teurer als der interne Standardschaltschrank, dafür entfallen aber alle zusätzlichen Kosten der Nacharbeit. Für einen Sonder-Schaltschrank ist die Ersparnis noch höher, da auch die Kosten der Planung und Konstruktion entfallen. Es gibt keine Experimente, keine langwierigen und abteilungsübergreifenden Dialoge und keine Überraschungen. Rundum eine schnelle Möglichkeit, komplett individualisierte Gehäuse in einer einmaligen Qualität zu bekommen. Mit dem neu entwickelten B2B Bestellprozess, also der Möglichkeit, die erstellte Konfiguration mit der Einkaufsabteilung zu teilen, ist es jetzt wirklich perfekt.",
          "CollapseText5": "Die FF Maschinen GmbH ist ein studentisches Konstruktionsbüro. Bei der Entwicklung von Maschinen wird von der Konzeption bis zur Produktentwicklung der gesamte Prozess angeboten. Der Fokus liegt dabei im Bereich Sondermaschinenbau unter anderem auch im Bereich Verpackungsmaschinen.",
          "CollapseText6": "In der Entwicklung wird der Fokus grundsätzlich auf die mechanische Konstruktion der Maschine gelegt und erst anschließend auf die Elektroplanung. Die Herausforderung besteht darin, zunächst einmal zu ermitteln, welche Steuerung, Antriebe usw. benötigt werden. Der Platz für die Schaltschränke wird in der Regel schon mit eingeplant, es passt dann aber meistens doch nicht ganz genau. Häufig passt also nur ein maßgeschneiderter Schaltschrank, insbesondere auch wegen der Tiefe von Umrichtern. Die Anforderungen an einen Schaltschrank sind grundsätzlich nicht besonders hoch, allerdings sind die passenden Ausschnitte für Schalter, Lüfter und Co häufig eine Herausforderung. Im besten Fall sollten diese schon fertig sein. Die Bohrmaschine musste in der Vergangenheit zu oft und zu kreativ eingesetzt werden.",
          "CollapseText7": "Der Konfigurator von ARMARiO führt unglaublich einfach durch die einzelnen Bearbeitungsschritte. In wenigen Minuten konnte ein passgenauer Schaltschrank erstellt werden. Komponenten wie Lüfter und Taster konnten visuell integriert werden, hierdurch wurde das individuelle Lochbild erstellt. Die Visualisierung des Schaltschranks konnte heruntergeladen und in die Planung der Anlage integriert werden. Neben der schnellen und einfachen Konstruktion hat auch die Lieferzeit von 10 Werktagen überzeugt.",
          "CollapseText8": "Die Firma FF Maschinen GmbH nutzt nun regelmäßig den ARMARiO und empfiehlt ihn begeistert weiter. Die unterschiedlichen Aufträge erfordern häufig individuelle Schaltschränke. Die einfache Bedienbarkeit und die schnelle Lieferung machen ARMARiO zu einem unverzichtbaren Partner. Durch die schnelle Lieferung kann eine höhere Flexibilität bei der Projektbearbeitung gelebt werden.",
          "Header1": "FALLSTUDIEN.",
          "TextBox1": "„Das ist für mich die einfachste Sache der Welt. So einfach, wie wenn ich einen Schütz kaufe.“",
          "TextBox2": "„Die Kontoverbindung von meinem Handy-Vertrag zu ändern, ist komplizierter als mit ARMARiO einen Schaltschrank zu konfigurieren.“",
          "TextBoxHeader1": "Hugo Beck Maschinenbau GmbH & Co. KG",
          "TextBoxHeader2": "FF Maschinenbau GmbH",
          "TextBoxSpan1": "Hugo Beck Maschinenbau GmbH & Co. KG, Dettingen, Gerhard Fink",
          "TextBoxSpan2": "FF Maschinen GmbH, Pforzheim, Felix Theurer"
        }
      }
    },
    "Helpers": {
      "OrderStatus": {
        "ANGEBOT": "Angebot",
        "BESTELLT": "Bestellt",
        "OFFEN": "Offen"
      }
    },
    "OrderConfirmation": {
      "AGBS": {
        "AGB": "AGB gelesen",
        "CancellationInfo": "Widerrufshinweise gelesen"
      },
      "Button1": "Okay",
      "Button2": "Kostenpflichtig Bestellen",
      "Button3": "Anmelden",
      "FormGroup": {
        "Label1": "Firma",
        "Label10": "Schaltschrank erstell von:",
        "Label11": "Datum:",
        "Label12": "Bitte geben Sie hier Ihre Bestell Nr. als individuelle Referenz an.",
        "Label2": "Postleitzahl",
        "Label3": "Ort",
        "Label4": "Straße + Hausnummer",
        "Label5": "Lieferadresse",
        "Label6": "Lieferadresse überschreiben",
        "Label7": "Stammdaten",
        "Label8": "Kundennummer:",
        "Label9": "Angebotsnummer:",
        "Save": "speichern"
      },
      "Head1": "Wir fertigen alle Schaltschränke auch in",
      "Head2": "Edelstahl",
      "Head3": "Rechnungsadresse",
      "Head4": "Rechnungsadresse überschreiben",
      "Head5": "Order",
      "Table": {
        "Head1": "Artikel Bezeichnung",
        "Head10": "19,00 % Mehrwertsteuer aus",
        "Head11": "EUR",
        "Head12": "Angebotsbetrag:",
        "Head2": "Menge",
        "Head3": "ME",
        "Head4": "Preis EUR",
        "Head5": "Wer EUR",
        "Head6": "Bezeichnung:",
        "Head7": "Artikel:",
        "Head8": "Stk",
        "Head9": "Nettobetrag"
      }
    },
    "Steps": {
      "Bar": {
        "Button1": "Andere Farbe wählen<",
        "Button2": "Weiter mit Ral 7035 Grobstruktur glänzend",
        "Main1": "Schrank",
        "Main2": "Komponenten",
        "Main3": "Farbe / Material",
        "Main4": "Abschluss",
        "Text1": "Du hast noch keine Farbe gewählt.",
        "Text2": "Bitte wähle eine Farbe aus.",
        "Text3": "Anmelden",
        "Text4": "Weiter"
      },
      "Colors": {
        "ColorHead": "Farbe",
        "CurrentColor": "Aktuelle Farbwahl:",
        "DefaultColor": "Standard Farben",
        "ESDColor": "ESD Farben",
        "LoadColor": "Farben werden geladen",
        "Main1": "Farbbeschichtung nach RAL",
        "MaterialOption1": "Stahlblech",
        "MaterialOption2": "Edelstahl",
        "RemoveColor": "Farbe entfernen",
        "StructurOption1": "Glatt glänzend",
        "StructurOption2": "Grobstruktur glänzend",
        "SubHeader1": "Material",
        "SubHeader2": "Struktur",
        "Surface": "Oberfläche: einseitig geschliffen K240"
      },
      "Components": {
        "CheckDeleteLayout1": "Wollen sie das Layout \"",
        "CheckDeleteLayout2": "wirklich löschen?",
        "CheckDeleteLayout3": "Nein!",
        "CheckDeleteLayout4": "Ja",
        "Circle": "Kreis",
        "ComponentLayoutSave": "Komponenten als Layout speichern",
        "ComponentName": "Komponenten:",
        "ComponentsBar1": "Komponenten",
        "ComponentsBar2": "Erweitert",
        "ComponentsBar3": "Layouts",
        "Datasheet": "Datenblatt",
        "Diameter": "Durchmesser:",
        "Distance": "Abstand",
        "ExpandedHead1": "Lineares Muster",
        "Height": "Höhe:",
        "Insert": "Einfügen",
        "LayoutInfo": "Es sind maximal 20 Komponenten erlaubt.",
        "LayoutName": "Layout Name",
        "Length": "Länge:",
        "LoadLayout": "Layout laden",
        "Main1": "Ihr Schaltschrank",
        "NewInfo": "NEU",
        "Number": "Anzahl:",
        "Optional": "(Optional)",
        "Rectangle": "Rechteck",
        "RequiredComponentText": "Bitte wählen Sie mindestens eine Komponente aus.",
        "Rotation": "Rotation:",
        "Rounding": "Rundung:",
        "Save": "Speichern",
        "SaveLayout": "Layout Speichern",
        "Size": "Größe:",
        "SubDepth": "Tiefe:",
        "SubHeight": "Höhe:",
        "SubWidth": "Breite:",
        "Width": "Breite:",
        "X": "X:",
        "Y": "Y:"
      },
      "ConfigurationsOverview": {
        "Head1": "Konfiguration löschen?\"",
        "Table": {
          "Head1": "Löschen?",
          "Head10": "Aktionen",
          "Head2": "Erstellt von",
          "Head3": "Status",
          "Head4": "Name",
          "Head5": "KonfigurationsNr",
          "Head6": "Model",
          "Head7": "Breite/Hoehe/Laenge",
          "Head8": "Material",
          "Head9": "Farbe",
          "Share": "Teilen",
          "View": "Ansehen"
        },
        "Text1": "Wollen sie ihre Konfiguration",
        "Text2": "wirklich löschen?"
      },
      "Finish": {
        "AddressHead1": "Abweichende Lieferadresse",
        "Button1": "Rechnungsadresse",
        "Button2": "Lieferadresse",
        "Button3": "Kostenpflichtig Bestellen",
        "Button4": "Als Vorlage Kopieren",
        "Button5": "Erneut Bestellen",
        "Button6": "Angebot Herunterladen",
        "Button7": "Zur Freigabe weiterleiten",
        "Button8": "Angebot erstellen",
        "Coating": "Beschichtung:",
        "ConfigurationNumber": "Konfigurationnummer:",
        "Depth": "Tiefe:",
        "DownloadHead1": "Angebot",
        "DownloadHead2": "erstellen",
        "DownloadHead3": "wurde erstellt",
        "Errors": {
          "AGB": "Sie müssen der AGB zustimmen",
          "AmountError": "Bitte geben Sie eine Stückzahl an",
          "ConfigurationError1": "Die Konfiguration gehört nicht zu Ihrem Account und ist nicht öffentlich erreichbar. Bitte loggen Sie sich ein oder erstellen Sie eine neue Konfiguration.",
          "ConfigurationError2": "Eingelogged mit: ",
          "NameError": "Bitte vergeben Sie eine interne Bezeichnung",
          "Revocation": "Sie müssen den Widerrufshinweisen zustimmen"
        },
        "Head1": "Gewähltes Modell",
        "Head2": "Farbe / Material",
        "Head3": "Lochbilder",
        "Head4": "Ansichten",
        "Head5": "Bestellen",
        "Head6": "Erneut Bestellen",
        "Height": "Höhe:",
        "InfoText1": "Es fallen eventuelle Mehrkosten an",
        "InfoText2": "Um diese Konfiguration zu bestellen oder ein Angebot zu erstellen, müssen Sie sich",
        "InfoText3": "Anmelden",
        "InfoText4": "Diese Konfiguration wurde bestellt.",
        "InfoText5": "Für diese Konfiguration wurde ein Angebot erstellt",
        "InfoText6": "und eine Freigabe an",
        "InfoText7": "geschickt",
        "InputLabel1": "Stückzahl",
        "InputLabel2": "Referenz",
        "InputLabel3": "Anmerkung",
        "InputLabel4": "Wunschliefertermin",
        "InputLabel5": "AGB gelesen",
        "InputLabel6": "Widerrufshinweise gelesen",
        "InputLabel7": "Angebotsnummer:",
        "Main1": "Bestellung prüfen und abschließen",
        "Material": "Material:",
        "ModalButton1": "Speichern",
        "ModalButton2": "Ok",
        "ModalHead1": "Bestellung für Freigabe weiterleiten",
        "ModalHead2": "Ansicht Schaltschrank",
        "ModalInfoText1": "Komponente",
        "ModalInfoText2": "Anzahl",
        "ModalInfoText3": "Nein, danke",
        "ModalInfoText4": "Angebot anfordern",
        "ModalInfoText5": "Okay",
        "ModalInfoText6": "Ihr Angebot wurde erstellt!",
        "ModalInfoText7": "Soll auch für die Komponenten in Ihrer Konfiguration ein Angebot erstellt werden?",
        "ModalInfoText8": "Ihr Angebot wurde angefordert!",
        "ModalInfoText9": "Die Komponenten wurden angefordert und ein Angebot wird Ihnen in Kürze zugeschickt!",
        "ModalLabel1": "Name",
        "ModalLabel2": "Firma",
        "ModalLabel3": "Postleitzahl",
        "ModalLabel4": "Ort",
        "ModalLabel5": "Straße + Hausnummer",
        "ModalLabel6": "E-Mail Adresse des Empfängers",
        "ModalLabel7": "Inhalt",
        "ModalLabel8": "Zusätzlicher Anhang:",
        "ModalSuccessText1": "Soll für die Komponenten in Ihrer Konfiguration ein Angebot erstellt werden?",
        "NoCoating": "keine Beschichtung",
        "NoHolePictures": "Keine Lochbilder",
        "NoStructur": "Keine Struktur gewählt",
        "NoViews": "Keine Ansichten verfügbar",
        "OrderHistory": "Bestellhistorie",
        "PaymentHead1": "Abweichende Rechnungsadresse",
        "Placeholder": {
          "Text1": "Anmerkung zur Bestellung..."
        },
        "Structur": "Stuktur:",
        "Surface": "Oberfläche:",
        "SurfaceText": "einseitig geschliffen K240",
        "Width": "Breite:"
      }
    }
  }], ["en-gb", {
    "AccountModal": {
      "Change": "Change",
      "Head1": "Edit account",
      "Head2": "PEdit profile picture",
      "Label1": "company",
      "Label2": "Account*",
      "Login": "Sign in",
      "LogoutMessage": "You have been logged out. You can register again here:",
      "MainAcc": {
        "CreateAccount": "Create an account",
        "Error": "Error",
        "ForgotPassword": "Forgot Password?",
        "Head1": "Sign in",
        "ResendPassword": "We have sent you an email containing instructions on how to reset your password.",
        "Success": "Success"
      },
      "Remove": "Remove",
      "RequiredFields": "* Required fields",
      "Save": "Save",
      "UploadImage": "Upload Image"
    },
    "BurgerMenu": {
      "AccountInformation": "Account Information",
      "Company": "Company administration",
      "DataProtection": "Datenschutz",
      "HelpAndSupport": {
        "Chat": "Support Chat",
        "FirewallSetting": "Firewall settings",
        "Head1": "Help and support",
        "Mail": "E-Mail: armario@fimab.eu",
        "Tel": "Support telephone: 07053 96839-65"
      },
      "Imprint": "Impressum",
      "Legal": {
        "DataProtection": "Data protection",
        "Header": "Legal",
        "Imprint": "Imprint"
      },
      "Login": "Sign in",
      "Logout": "Log out",
      "OrderHistory": "Order overview",
      "Support": {
        "FirewallSetting": "ARMARIO Firewall settings for companies",
        "Head1": "Support",
        "SuppChat": "Support chat",
        "SuppMail1": "Support email address:",
        "SuppMail2": "armario@fimab.eu",
        "SuppTel1": "Support phone number:",
        "SuppTel2": "+49 (7053) 96839-65"
      },
      "UserProfil": "User profile"
    },
    "Company": {
      "CompanyCard": {
        "Leave": "Leave"
      },
      "Employees": {
        "Header": "Employees"
      },
      "Header": "Your Company",
      "OpenInvites": {
        "Filter": "Status Filter",
        "FilterOptions": {
          "Accepted": "Accepted",
          "Declined": "Declined",
          "Open": "Open"
        },
        "Header": "Open Invites"
      },
      "OpenRequests": {
        "Filter": "Status Filter",
        "FilterOptions": {
          "Accepted": "Accepted",
          "Declined": "Declined",
          "Open": "Open"
        },
        "Header": "Open Requests"
      },
      "Table": {
        "Accept": "Accept",
        "Actions": "Actions",
        "Cancel": "Cancel",
        "Created": "Created at",
        "Date": "Invited at",
        "Decline": "Decline",
        "Department": "Department",
        "EMail": "E-Mail",
        "Empty": "No open Invites",
        "EmptyEmployees": "No Employees Found",
        "Name": "Name",
        "Origin": "Origin",
        "OriginInvite": "Invited",
        "OriginManual": "Manualy Requested",
        "OriginSystem": "Detected By System",
        "Status": "Status",
        "StatusAccepted": "Accepted",
        "StatusDeclined": "Declined",
        "StatusWait": "Wait for approval"
      }
    },
    "Configurations": {
      "AutoSave": {
        "Text1": "Your configuration will be saved automatically",
        "Text2": "Sign in to enable autosave"
      },
      "ModalSelection": {
        "Cabinet": {
          "Klemmkasten": "",
          "Modell 1": "(Single door)",
          "Modell 1: Anschlag Links": "",
          "Modell 1: Anschlag Rechts": "",
          "Modell 2": "(Double door)",
          "Modell 3": "(Tripple door)"
        },
        "FooterText1": "We also manufacture all control cabinets",
        "FooterTextSpan": "stainless steel",
        "Main1": "Enter the dimensions of your control cabinet here.",
        "Main2": "Choose your appropriate control cabinet type",
        "SelectButton": "Choose"
      },
      "ModelBox": {
        "Main1": "Selected model"
      },
      "OptionBox": {
        "InfoText1": "Thank you for your interest. With your selection you have contributed to the further development of these features!",
        "InfoText2": "(The above features are not currently being installed)",
        "Label1": "Switch cabinet roof",
        "Label2": "Switch cabinet socket",
        "Label2Height": "Socket height",
        "Label3": "Internal hinges",
        "Main1": "Options",
        "OkButton": "Ok",
        "WarningBoxHeadline": "Danger",
        "WarningBoxText1": "Thank you for your interest. With your selection you have contributed to the further development of these features!",
        "WarningBoxText2": "The selected features are not yet being installed."
      },
      "SelectedModal": {
        "Dimensions": "Dimensions of the mounting plate",
        "DimensionsHeight": "Height:",
        "DimensionsWidth": "Width:",
        "Label1": "The internal term",
        "Label2": "Width",
        "Label3": "Height",
        "Label4": "depth",
        "Main1": "Dimensions",
        "NoMountingPlate": "This model does not include a mounting plate"
      }
    },
    "Dashboard": {
      "Auth": {
        "ContinueConfiguration": {
          "ConfigColor": "Color:",
          "ConfigMaterial": "Material:",
          "ConfigName": "Name:",
          "ContinueButton": "continue configuring",
          "Header1": "Continue configuring",
          "Sub1": "Continue configuring your last configured control cabinet"
        },
        "LastConfigurations": {
          "ConfigAction": "Actions",
          "ConfigModel": "Model",
          "ConfigName1": "Name",
          "ConfigNr1": "Config no.",
          "ConfigStatus": "Status",
          "ContinueButton": "Continue",
          "Header1": "Your last configurations",
          "MineConfigsBtn": "All configurations",
          "ViewButton": "View"
        },
        "OrderConfiguration": {
          "Header1": "Control cabinet",
          "Header2": "order?",
          "OrderColor": "Color:",
          "OrderMaterial": "Material:",
          "OrderName": "Name:",
          "Sub1": "Order your last configured control cabinet in just a few clicks",
          "checkButton": "Check and order"
        },
        "Tipps": {
          "Header1": "Tips from the Armario world",
          "Link1": "https://www.fimab.eu/schaltschrank-konfigurator/der-schnelle-online-bestellprozess",
          "Link2": "https://www.fimab.eu/schaltschrank-konfigurator/software-fuer-die-schaltschrank-konstruktion",
          "Link3": "https://www.fimab.eu/schaltschrank-konfigurator/edelstahl-ausfuehrungen-bei-allen-schaltschraenken",
          "Link4": "https://www.fimab.eu/schaltschrank-konfigurator/schluss-mit-verstaubten-schaltschraenken",
          "Link5": "https://www.fimab.eu/schaltschrank-konfigurator/schaltschraenke-mit-ableitender-beschichtung-esd",
          "Link6": "https://www.fimab.eu/schaltschrank-konfigurator/nie-wieder-loecher-in-schaltschraenke-bohren",
          "LinkHeader1": "The fastest online ordering process",
          "LinkHeader2": "Design software for control cabinets",
          "LinkHeader3": "Stainless steel versions for all control cabinets",
          "LinkHeader4": "No more dusty control cabinets",
          "LinkHeader5": "Control cabinets with ESD coating",
          "LinkHeader6": "Never drill holes in control cabinets again",
          "Tipp1": "Model selection",
          "Tipp2": "Design hole patterns",
          "Tipp3": "Define color and material",
          "Tipp4": "Place an order"
        }
      },
      "Header": {
        "CTAAuth": "Start new configuration",
        "CTANoAuth": "Sign in",
        "Main1": "ONLINE",
        "Main2": "CONTROL CABINET",
        "Main3": "CONFIGURATOR.",
        "Sub": "ARMARiO STANDS FOR QUICKNESS.",
        "Sub2": "IN CUSTOMIZED INDIVIDUAL CONTROL CABINET CONSTRUCTION.",
        "Text1": "Then order received. The advantage for you: The control cabinet is delivered completely according to your specifications with all holes and recesses. This saves you time-consuming rework and allows you to start assembling your intended add-on components straight away.",
        "Text2": "ARMARiO - Save time and money!"
      },
      "NoAuth": {
        "Advantages": {
          "Header1": "YOUR",
          "Header2": "BENEFITS",
          "Header3": "AT",
          "Header4": "A GLANCE."
        },
        "FimabInfo": {
          "BtnText1": "Start new configuration",
          "Header1": "FiMAB STANDS FOR COMPETENCE.",
          "Header2": "IN THE PRODUCTION OF SATISFIED CUSTOMERS.",
          "ListHeader1": "FiMAB - We will inspire you!",
          "ListPoint1": "32 years of experience in mechanical engineering",
          "ListPoint2": "Family business in the 2nd generation",
          "ListPoint3": "More than 700 satisfied customers in Germany and Europe",
          "ListPoint4": "Over 500 customer-specific control cabinet variants delivered",
          "Main1": "CONFIGURE",
          "Main2": "NOW",
          "Main3": "YOUR",
          "Main4": "OWN",
          "Main5": "CONTROL CABINET",
          "Main6": "ON",
          "Main7": "ARMARiO",
          "Text1": "You know it too: “Yesterday, 20 control cabinets were ordered in stock and then, right now, the supplier of frequency converters is changing its product line and from now on the devices no longer fit in the cabinets. Just a four-figure amount for the ton stored.",
          "Text2": "In the co-creation process, with our control cabinet configurator, you as the user design your product exactly as you need it. Order at the right time and in the quantity you need now. Through the interactive process chain - you help shape the value creation process - and the automated interfaces, we combine the advantages of two worlds. The advantages of mass production and those of individual one-off production. Mass customization means customer-specific series production.",
          "Text3": "We at FiMAB have over 30 years of experience in the production of batch size 1 for special machine construction. From this we have developed an automated process chain from design to delivery. We produce batch size 1 in series at realistic prices. No overproduction, no storage, no waste. Such an interactive customer-supplier process makes sense not only economically, but also ecologically.",
          "TextBreak": "Our insider tip for you: take new paths with us and impress your colleagues."
        },
        "Information": {
          "InfoStep1": "Time saving",
          "InfoStep2": "Cost savings",
          "InfoStep3": "Optimal size",
          "InfoStep4": "We also produce all control cabinets in",
          "InfoStep4Bold": "stainless steel",
          "TextBlock1": "Order by",
          "TextBlock2": "4:00 p.m",
          "TextBlock3": "and we will produce your control cabinet by"
        },
        "ReadyToInstall": {
          "Header1": "READY TO INSTALL",
          "Header2": "CONTROL CABINETS.",
          "StartConfigs": "Start new configuration",
          "Text1": "Congratulations - the fact that you're taking the time to read this brochure already makes you a winner. These few minutes will save your company days of planning and rework.",
          "Text2": "This brochure to show you how you can get the perfect control cabinet in just a few minutes. This saves you a lot of time and gives you the optimal solution for your packaging machine. Using customer results, you will experience this process in a real and understandable way."
        },
        "Steps": {
          "Header1": "Variant and dimension",
          "Header2": "Hole patterns",
          "Header3": "Material and color",
          "Header4": "Place an order",
          "Main1": "ONLY",
          "Main2": "4 STEPS",
          "Main3": "TO THE PERFECT",
          "Main4": "CONTROL CABINET.",
          "TextBox1": "Select a suitable variant for your control cabinet or terminal box from the models. Define the type of door and the hinge side. Then enter the desired dimensions. All models are scaled to the millimeter and therefore fit exactly into every system module.",
          "TextBox2": "Generate every conceivable hole pattern by dragging and dropping the prefabricated components. The configurator pays direct attention to plausibility, checks for collisions and displays them if necessary. The digital twin of the control cabinet ensures functionality and is the basis for a standard-compliant design.",
          "TextBox3": "Just one click decides whether your control cabinet is made of sheet steel or stainless steel. Choose the color and surface texture freely according to your taste, the CI of your company or the color request of your customer. Each control cabinet is produced directly in your desired color.",
          "TextBox4": "Not only can you design, but you can also place orders directly online, 24/7 from anywhere in the world. The data is transmitted directly to our production, which guarantees a short production time. Thanks to the new B2B online ordering process, you can also send your order request to a colleague, e.g. B. forward in purchasing.",
          "TextHeader1": "All control cabinets are scaled to the millimeter and therefore fit into any gap.",
          "TextHeader2": "You will never have to edit a control cabinet with a drill again.",
          "TextHeader3": "In any case, you save yourself the repainting process.",
          "TextHeader4": "10 days of production time for an individual control cabinet housing is unique in the industry.",
          "startConfigBtn": "Start new configuration"
        },
        "Studies": {
          "ButtonText": "Read the entire case study.",
          "CollapseHead1": "initial situation:",
          "CollapseHead2": "Challenge:",
          "CollapseHead3": "The solution:",
          "CollapseHead4": "Long-term:",
          "CollapseText1": "Hugo Beck Maschinenbau is a specialist in horizontal flowpack, film and paper packaging machines. In the past, Hugo Beck was used to buying standard control cabinets and drilling holes himself. To avoid time-consuming rework, internal standard cabinets are already manufactured. Unfortunately, standard cabinets are not suitable in all cases, even if they are refinished.",
          "CollapseText2": "The control cabinet should be very small. At the same time, the housing also has to be very deep because there is a frequency converter in the control. There are almost no standard solutions for this. Deviations from the internal standard housings always require a great deal of effort in coordination and production. A time and cost saving solution was sought. Time-consuming follow-up work should be avoided. A special design from the previous supplier required separate drawings and a major coordination process. This process costs a lot of time and money. After many drawings and experiments, Hugo Beck became aware of the ARMARiO.",
          "CollapseText3": "With the online configurator, Hugo Beck now has a solution that allows the company to completely ignore the mechanical construction. The head of electrical design can carry out the entire design process completely himself. The requirements are then passed on to purchasing with all data such as item number, dimensions, design and price. A very nice solution for an electrician who needs a cabinet with special dimensions. In addition, the exact components for defining the hole patterns in the system that Hugo Beck needs in his daily work are listed. Even if an exotic hole pattern is required, this can be created quickly and easily using the rectangle and circle functions. Simply changing from steel to stainless steel is also very convenient. A flexible system that Hugo Beck delivers results very quickly. Whenever special solutions are required, the online configurator is the preferred tool.",
          "CollapseText4": "The ARMARiO control cabinet is slightly more expensive than the internal standard control cabinet, but there are no additional costs for rework. The savings are even greater for a special control cabinet, as the planning and construction costs are also eliminated. There are no experiments, no lengthy and cross-departmental dialogues and no surprises. All in all, a quick way to get completely customized housings in unique quality. With the newly developed B2B ordering process, i.e. the ability to share the created configuration with the purchasing department, it is now really perfect.",
          "CollapseText5": "The FF Maschinen GmbH is a student design office. When developing machines, the entire process is offered, from conception to product development. The focus is on the area of ​​special machine construction, including packaging machines.",
          "CollapseText6": "During development, the focus is generally on the mechanical design of the machine and only then on the electrical planning. The challenge is to first determine which controls, drives, etc. are needed. The space for the control cabinets is usually already planned, but it usually doesn't fit exactly. Often only a tailor-made control cabinet is suitable, especially because of the depth of the converters. The requirements for a control cabinet are generally not particularly high, but finding the right cutouts for switches, fans, etc. is often a challenge. In the best case scenario, these should already be finished. In the past, the drill had to be used too often and too creatively.",
          "CollapseText7": "The ARMARiO configurator guides you through the individual processing steps incredibly easily. A precisely fitting control cabinet could be created in just a few minutes. Components such as fans and buttons could be visually integrated, thereby creating the individual hole pattern. The visualization of the control cabinet could be downloaded and integrated into the planning of the system. In addition to the quick and easy construction, the delivery time of 10 working days was also impressive.",
          "CollapseText8": "The Company FF Maschinen GmbH now uses the ARMARiO regularly and recommends it enthusiastically. The different orders often require individual control cabinets. The ease of use and fast delivery make ARMARiO an indispensable partner. The fast delivery means greater flexibility can be achieved in project processing.",
          "Header1": "CASE STUDIES.",
          "TextBox1": "“This is the easiest thing in the world for me. As easy as buying a contactor.”",
          "TextBox2": "“Changing the account details of my cell phone contract is more complicated than configuring a control cabinet with ARMARiO.”",
          "TextBoxHeader1": "Hugo Beck Maschinenbau GmbH & Co. KG",
          "TextBoxHeader2": "FF Maschinenbau GmbH",
          "TextBoxSpan1": "Hugo Beck Maschinenbau GmbH & Co. KG, Dettingen, Gerhard Fink",
          "TextBoxSpan2": "FF Maschinen GmbH, Pforzheim, Felix Theurer"
        }
      }
    },
    "Helpers": {
      "OrderStatus": {
        "ANGEBOT": "Offer",
        "BESTELLT": "Ordered",
        "OFFEN": "Open"
      }
    },
    "OrderConfirmation": {
      "AGBS": {
        "AGB": "Read the terms and conditions",
        "CancellationInfo": "Read cancellation instructions"
      },
      "Button1": "Okay",
      "Button2": "Order for a fee",
      "Button3": "Sign in",
      "FormGroup": {
        "Label1": "company",
        "Label10": "Control cabinet created by:",
        "Label11": "Date:",
        "Label12": "Please enter your order number here as an individual reference.",
        "Label2": "Postal code",
        "Label3": "Location",
        "Label4": "Street + house number",
        "Label5": "delivery address",
        "Label6": "Overwrite delivery address",
        "Label7": "base data",
        "Label8": "Customer number:",
        "Label9": "Offer number:",
        "Save": "save"
      },
      "Head1": "We also manufacture all control cabinets",
      "Head2": "stainless steel",
      "Head3": "Billing address",
      "Head4": "Overwrite billing address",
      "Head5": "Order",
      "Table": {
        "Head1": "Item description",
        "Head10": "19,00 % VAT",
        "Head11": "EUR",
        "Head12": "Offer amount:",
        "Head2": "Crowd",
        "Head3": "ME",
        "Head4": "Price EUR",
        "Head5": "Who EUR",
        "Head6": "Designation:",
        "Head7": "Article:",
        "Head8": "Stk",
        "Head9": "net amount"
      }
    },
    "Steps": {
      "Bar": {
        "Button1": "Choose another color<",
        "Button2": "Continue with Ral 7035 coarse structure, shiny",
        "Main1": "Cabinet",
        "Main2": "Components",
        "Main3": "Color / Material",
        "Main4": "Finish",
        "Text1": "You haven't chosen a color yet.",
        "Text2": "Please select a color.",
        "Text3": "Sign in",
        "Text4": "Next"
      },
      "Colors": {
        "ColorHead": "Color",
        "CurrentColor": "Current color choice:",
        "DefaultColor": "Standard Farben",
        "ESDColor": "ESD colors",
        "LoadColor": "Colors are loading",
        "Main1": "Color coating according to RAL",
        "MaterialOption1": "Sheet steel",
        "MaterialOption2": "stainless steel",
        "RemoveColor": "Remove paint",
        "StructurOption1": "Smooth shiny",
        "StructurOption2": "Coarse structure shiny",
        "SubHeader1": "Material",
        "SubHeader2": "structure",
        "Surface": "Surface: ground on one side K240"
      },
      "Components": {
        "CheckDeleteLayout1": "Do you want the layout? \"",
        "CheckDeleteLayout2": "really delete?",
        "CheckDeleteLayout3": "No!",
        "CheckDeleteLayout4": "Yes",
        "Circle": "Circle",
        "ComponentLayoutSave": "Save components as layout",
        "ComponentName": "Components:",
        "ComponentsBar1": "Components",
        "ComponentsBar2": "Advanced",
        "ComponentsBar3": "Layouts",
        "Datasheet": "data sheet",
        "Diameter": "diameter:",
        "Distance": "Distance",
        "ExpandedHead1": "Linear pattern",
        "Height": "Height:",
        "Insert": "Insert",
        "LayoutInfo": "A maximum of 20 components are allowed.",
        "LayoutName": "Layout Name",
        "Length": "length:",
        "LoadLayout": "Load layout",
        "Main1": "Your switch cabinet",
        "NewInfo": "NEW",
        "Number": "Number:",
        "Optional": "(Optional)",
        "Rectangle": "rectangle",
        "RequiredComponentText": "Please select at least one component.",
        "Rotation": "Rotation:",
        "Rounding": "rounding:",
        "Save": "Save",
        "SaveLayout": "Save layout",
        "Size": "Size:",
        "SubDepth": "depth:",
        "SubHeight": "Height:",
        "SubWidth": "Width:",
        "Width": "Width:",
        "X": "X:",
        "Y": "Y:"
      },
      "ConfigurationsOverview": {
        "Head1": "Delete configuration?\"",
        "Table": {
          "Head1": "Delete?",
          "Head10": "Actions",
          "Head2": "Created by",
          "Head3": "Status",
          "Head4": "Name",
          "Head5": "Configuration no.",
          "Head6": "Model",
          "Head7": "Width/height/length",
          "Head8": "Material",
          "Head9": "Color",
          "Share": "Share",
          "View": "View"
        },
        "Text1": "Do you want your configuration?",
        "Text2": "really delete?"
      },
      "Finish": {
        "AddressHead1": "Different delivery address",
        "Button1": "Billing address",
        "Button2": "delivery address",
        "Button3": "Place Order",
        "Button4": "Copy as a template",
        "Button5": "Re-order",
        "Button6": "Download offer",
        "Button7": "Forward for approval",
        "Button8": "Create offer",
        "Coating": "Coating:",
        "ConfigurationNumber": "Configuration number:",
        "Depth": "Depth:",
        "DownloadHead1": "Offer",
        "DownloadHead2": "create",
        "DownloadHead3": "was created",
        "Errors": {
          "AGB": "You must agree to the terms and conditions",
          "AmountError": "Please enter a quantity",
          "ConfigurationError1": "The configuration is not part of your account and is not publicly accessible. Please log in or create a new configuration.",
          "ConfigurationError2": "Logged in with:",
          "NameError": "Please enter an internal name",
          "Revocation": "You must agree to the cancellation instructions"
        },
        "Head1": "Selected model",
        "Head2": "Color / Material",
        "Head3": "Hole patterns",
        "Head4": "Views",
        "Head5": "Order",
        "Head6": "Re-order",
        "Height": "Height:",
        "InfoText1": "Additional costs may apply",
        "InfoText2": "To order this configuration or create a quote, you must",
        "InfoText3": "Sign in",
        "InfoText4": "This configuration has been ordered.",
        "InfoText5": "An offer has been created for this configuration",
        "InfoText6": "and a release",
        "InfoText7": "sent",
        "InputLabel1": "Number of pieces",
        "InputLabel2": "Reference",
        "InputLabel3": "Note",
        "InputLabel4": "Desired delivery date",
        "InputLabel5": "Read the terms and conditions",
        "InputLabel6": "Read cancellation instructions",
        "InputLabel7": "Offer number:",
        "Main1": "Check and complete your order",
        "Material": "Material:",
        "ModalButton1": "Save",
        "ModalButton2": "Ok",
        "ModalHead1": "Forward order for approval",
        "ModalHead2": "View of the control cabinet",
        "ModalInfoText1": "component",
        "ModalInfoText2": "Number",
        "ModalInfoText3": "No, thank you",
        "ModalInfoText4": "Request a quote",
        "ModalInfoText5": "Okay",
        "ModalInfoText6": "Your offer has been created!",
        "ModalInfoText7": "Should an offer also be created for the components in your configuration?",
        "ModalInfoText8": "Your offer has been requested!",
        "ModalInfoText9": "The components have been requested and a quote will be sent to you shortly!",
        "ModalLabel1": "Name",
        "ModalLabel2": "company",
        "ModalLabel3": "Postal code",
        "ModalLabel4": "Location",
        "ModalLabel5": "Street + house number",
        "ModalLabel6": "Recipient's e-mail address",
        "ModalLabel7": "Contents",
        "ModalLabel8": "Additional attachment:",
        "ModalSuccessText1": "Should an offer be created for the components in your configuration?",
        "NoCoating": "no coating",
        "NoHolePictures": "No hole patterns",
        "NoStructur": "no structure chosen",
        "NoViews": "No views available",
        "OrderHistory": "Order history",
        "PaymentHead1": "Different billing address",
        "Placeholder": {
          "Text1": "Note on order..."
        },
        "Structur": "structure:",
        "Surface": "Oberfläche:",
        "SurfaceText": "ground on one side K240",
        "Width": "Width:"
      }
    }
  }], ["es-es", {
    "AccountModal": {
      "Change": "Cambiar",
      "Head1": "Editar cuenta",
      "Head2": "Editar foto de perfil",
      "Label1": "compañía",
      "Label2": "Cuenta*",
      "Login": "Iniciar sesión",
      "LogoutMessage": "Se te ha cerrado la sesión. Puedes registrarte nuevamente aquí:",
      "MainAcc": {
        "CreateAccount": "Crear una cuenta",
        "Error": "Error",
        "ForgotPassword": "¿Olvidaste tu contraseña?",
        "Head1": "Iniciar sesión",
        "ResendPassword": "Le hemos enviado un correo electrónico con instrucciones sobre cómo restablecer su contraseña.",
        "Success": "Éxito"
      },
      "Remove": "Eliminar",
      "RequiredFields": "* Campos obligatorios",
      "Save": "Ahorrar",
      "UploadImage": "Subir imagen"
    },
    "BurgerMenu": {
      "AccountInformation": "Información de la cuenta",
      "Company": "administración de la empresa",
      "DataProtection": "Protección de datos",
      "HelpAndSupport": {
        "Chat": "Chat de soporte",
        "FirewallSetting": "Configuración del cortafuegos",
        "Head1": "Ayuda y soporte",
        "Mail": "E-Mail: armario@fimab.eu",
        "Tel": "Teléfono de soporte: 07053 96839-65"
      },
      "Imprint": "imprimir",
      "Legal": {
        "DataProtection": "Protección de datos",
        "Header": "Información legal",
        "Imprint": "imprimir"
      },
      "Login": "Iniciar sesión",
      "Logout": "Finalizar la sesión",
      "OrderHistory": "Resumen de pedidos",
      "Support": {
        "FirewallSetting": "Configuración del firewall ARMARIO para empresas",
        "Head1": "Apoyo",
        "SuppChat": "Chat de soporte",
        "SuppMail1": "Dirección de correo electrónico de soporte:",
        "SuppMail2": "armario@fimab.eu",
        "SuppTel1": "Número de teléfono de soporte: ",
        "SuppTel2": "+49 (7053) 96839-65"
      },
      "UserProfil": "Perfil de usuario"
    },
    "Company": {
      "CompanyCard": {
        "Leave": "Dejar"
      },
      "Employees": {
        "Header": "Empleados"
      },
      "Header": "tu empresa",
      "OpenInvites": {
        "Filter": "Filtros de estado",
        "FilterOptions": {
          "Accepted": "Aceptado",
          "Declined": "Rechazado",
          "Open": "Abierto"
        },
        "Header": "invitaciones abiertas"
      },
      "OpenRequests": {
        "Filter": "Filtros de estado",
        "FilterOptions": {
          "Accepted": "Aceptado",
          "Declined": "Rechazado",
          "Open": "Abierto"
        },
        "Header": "Consultas abiertas"
      },
      "Table": {
        "Accept": "Aceptado",
        "Actions": "Promociones",
        "Cancel": "Cancelar",
        "Created": "Creado el",
        "Date": "Invitado el",
        "Decline": "Rechazado",
        "Department": "Departamento",
        "EMail": "correo electrónico",
        "Empty": "No hay invitaciones abiertas",
        "EmptyEmployees": "No hay empleados disponibles",
        "Name": "nombre",
        "Origin": "Origen",
        "OriginInvite": "Invitado",
        "OriginManual": "Solicitado manualmente",
        "OriginSystem": "Detectado por el sistema",
        "Status": "estado",
        "StatusAccepted": "Aceptado",
        "StatusDeclined": "Rechazado",
        "StatusWait": "Esperando confirmación"
      }
    },
    "Configurations": {
      "AutoSave": {
        "Text1": "Su configuración se guardará automáticamente",
        "Text2": "AInicie sesión para habilitar el guardado automático"
      },
      "ModalSelection": {
        "Cabinet": {
          "Klemmkasten": "",
          "Modell 1": "(Puerta simple)",
          "Modell 1: Anschlag Links": "",
          "Modell 1: Anschlag Rechts": "",
          "Modell 2": "(Puerta doble)",
          "Modell 3": "(Puerta triple)"
        },
        "FooterText1": "También fabricamos todos los armarios de control.",
        "FooterTextSpan": "acero inoxidable",
        "Main1": "Introduzca aquí las dimensiones de su armario de control.",
        "Main2": "Elija el tipo de armario de control adecuado",
        "SelectButton": "Elegir"
      },
      "ModelBox": {
        "Main1": "Modelo seleccionado"
      },
      "OptionBox": {
        "InfoText1": "Gracias por tu interés. ¡Con su selección ha contribuido al desarrollo de estas funciones!",
        "InfoText2": "(Las funciones mencionadas anteriormente no se están instalando actualmente.)",
        "Label1": "Techo del armario de control",
        "Label2": "Base del armario de control",
        "Label2Height": "Altura base",
        "Label3": "Bisagras internas",
        "Main1": "Opciones",
        "OkButton": "DE ACUERDO",
        "WarningBoxHeadline": "Peligro",
        "WarningBoxText1": "Gracias por tu interés. ¡Con su selección ha contribuido al desarrollo de estas funciones!",
        "WarningBoxText2": "Las funciones seleccionadas aún no se están instalando."
      },
      "SelectedModal": {
        "Dimensions": "Dimensiones de la placa de montaje.",
        "DimensionsHeight": "Altura:",
        "DimensionsWidth": "Ancho:",
        "Label1": "Designación interna",
        "Label2": "Ancho",
        "Label3": "Altura",
        "Label4": "profundidad",
        "Main1": "Dimensiones",
        "NoMountingPlate": "Este modelo no incluye placa de montaje."
      }
    },
    "Dashboard": {
      "Auth": {
        "ContinueConfiguration": {
          "ConfigColor": "Color:",
          "ConfigMaterial": "material:",
          "ConfigName": "nombre:",
          "ContinueButton": "Continuar configurando",
          "Header1": "Continuar configurando",
          "Sub1": "Continúe configurando su último gabinete configurado"
        },
        "LastConfigurations": {
          "ConfigAction": "Promociones",
          "ConfigModel": "Model",
          "ConfigName1": "nombre",
          "ConfigNr1": "Configuración no.",
          "ConfigStatus": "Modelo",
          "ContinueButton": "Más",
          "Header1": "Tus últimas configuraciones",
          "MineConfigsBtn": "Todas las configuraciones",
          "ViewButton": "Vista"
        },
        "OrderConfiguration": {
          "Header1": "Gabinete de control",
          "Header2": "orden?",
          "OrderColor": "Color:",
          "OrderMaterial": "material:",
          "OrderName": "nombre:",
          "Sub1": "Pide tu último armario configurado en tan solo unos clics",
          "checkButton": "Consulta y ordena"
        },
        "Tipps": {
          "Header1": "Consejos del mundo Armario",
          "Link1": "https://www.fimab.eu/schaltschrank-konfigurator/der-schnelle-online-bestellprozess",
          "Link2": "https://www.fimab.eu/schaltschrank-konfigurator/software-fuer-die-schaltschrank-konstruktion",
          "Link3": "https://www.fimab.eu/schaltschrank-konfigurator/edelstahl-ausfuehrungen-bei-allen-schaltschraenken",
          "Link4": "https://www.fimab.eu/schaltschrank-konfigurator/schluss-mit-verstaubten-schaltschraenken",
          "Link5": "https://www.fimab.eu/schaltschrank-konfigurator/schaltschraenke-mit-ableitender-beschichtung-esd",
          "Link6": "https://www.fimab.eu/schaltschrank-konfigurator/nie-wieder-loecher-in-schaltschraenke-bohren",
          "LinkHeader1": "El proceso de pedido online más rápido",
          "LinkHeader2": "Software de diseño para armarios de control",
          "LinkHeader3": "Versiones de acero inoxidable para todos los armarios de control.",
          "LinkHeader4": "Se acabaron los armarios de control polvorientos",
          "LinkHeader5": "Armarios de control con pintura ESD",
          "LinkHeader6": "Nunca más taladres agujeros en los armarios de control",
          "Tipp1": "Selección de modelo",
          "Tipp2": "Patrones de agujeros de diseño",
          "Tipp3": "Definir color y material",
          "Tipp4": "hacer un pedido"
        }
      },
      "Header": {
        "CTAAuth": "Iniciar nueva configuración",
        "CTANoAuth": "Iniciar sesión",
        "Main1": "EN LÍNEA",
        "Main2": "GABINETE DE CONTROL",
        "Main3": "CONFIGURADOR.",
        "Sub": "EL GABINETE ES VELOCIDAD.",
        "Sub2": "EN CONSTRUCCIÓN DE GABINETE DE CONTROL INDIVIDUAL A MEDIDA.",
        "Text1": "DLuego se recibió el pedido. La ventaja para usted: el armario de control se entrega completamente según sus especificaciones, con todos los orificios y huecos. Esto le ahorra mucho tiempo en el posprocesamiento y le permite comenzar a ensamblar los componentes adicionales previstos de inmediato.",
        "Text2": "ARMARiO - ¡Ahorra tiempo y costes!"
      },
      "NoAuth": {
        "Advantages": {
          "Header1": "SU",
          "Header2": "VENTAJAS",
          "Header3": "EN",
          "Header4": "UNA MIRADA."
        },
        "FimabInfo": {
          "BtnText1": "Iniciar nueva configuración",
          "Header1": "FiMAB SIGNIFICA COMPETENCIA.",
          "Header2": "EN LA PRODUCCIÓN DE CLIENTES SATISFECHOS.",
          "ListHeader1": "FiMAB - ¡Te inspiraremos!",
          "ListPoint1": "32 años de experiencia en ingeniería mecánica.",
          "ListPoint2": "Empresa familiar de segunda generación.",
          "ListPoint3": "Más de 700 clientes satisfechos en Alemania y Europa",
          "ListPoint4": "Se entregan más de 500 variantes de armarios de control específicas para cada cliente",
          "Main1": "CONFIGURAR",
          "Main2": "ELLA",
          "Main3": "AHORA",
          "Main4": "SU",
          "Main5": "GABINETE DE CONTROL",
          "Main6": "EN",
          "Main7": "ARMARiO",
          "Text1": "Usted también lo sabe: “Ayer pedimos 20 armarios de control en stock y ahora mismo el proveedor de convertidores de frecuencia está cambiando su línea de productos y a partir de ahora los dispositivos ya no caben en los armarios. Sólo una cantidad de cuatro cifras por el contenedor almacenado.",
          "Text2": "En el proceso de cocreación, con nuestro configurador de armarios de control, usted, como usuario, diseña su producto exactamente como lo necesita. Haz tu pedido en el momento adecuado y en la cantidad que necesitas ahora. A través de la cadena de procesos interactiva (usted ayuda a dar forma al proceso de creación de valor) y las interfaces automatizadas, combinamos las ventajas de dos mundos. Las ventajas de la producción en masa y las de la producción individual y única. La personalización masiva significa producción en serie específica para el cliente.",
          "Text3": "En FiMAB tenemos más de 30 años de experiencia en la producción de lotes de tamaño 1 para la construcción de máquinas especiales. A partir de esto hemos desarrollado una cadena de proceso automatizada desde el diseño hasta la entrega. Producimos lotes de tamaño 1 en serie a precios realistas. Sin sobreproducción, sin almacenamiento, sin desperdicio. Un proceso tan interactivo entre cliente y proveedor tiene sentido no sólo desde el punto de vista económico sino también ecológico.",
          "TextBreak": "Nuestro consejo privilegiado para usted: tome nuevos caminos con nosotros e impresione a sus colegas."
        },
        "Information": {
          "InfoStep1": "Ahorro de tiempo",
          "InfoStep2": "Ahorro de costos",
          "InfoStep3": "Tamaño de pasaporte óptimo",
          "InfoStep4": "También fabricamos todos los armarios de control.",
          "InfoStep4Bold": "acero inoxidable",
          "TextBlock1": "Ordenar por",
          "TextBlock2": "16:00 horas",
          "TextBlock3": "y producimos su gabinete de control por"
        },
        "ReadyToInstall": {
          "Header1": "LISTO PARA INSTALAR",
          "Header2": "ARMARIOS DE INTERRUPTOR.",
          "StartConfigs": "Iniciar nueva configuración",
          "Text1": "Felicitaciones, el hecho de que ahora se esté tomando el tiempo y Leer este folleto ya lo convertirá en un ganador. Estos pocos minutos le ahorrará a su empresa días de planificación y reelaboración a largo plazo.",
          "Text2": "Hemos elaborado este folleto para mostrarle cómo en tan sólo unos pocos En tan solo unos minutos se puede conseguir un armario de control perfecto. Esto le ahorra mucho tiempo y le da la solución óptima para su máquina envasadora. Utilizando los resultados de los clientes, experimentará este proceso de una manera real y comprensible."
        },
        "Steps": {
          "Header1": "Variante y dimensión",
          "Header2": "Patrones de agujeros",
          "Header3": "Material y color",
          "Header4": "hacer un pedido",
          "Main1": "SOLO",
          "Main2": "4 PASOS",
          "Main3": "A LO PERFECTO",
          "Main4": "GABINETE DE CONTROL.",
          "TextBox1": "Elija entre los modelos la variante adecuada para su armario de control o caja de terminales. Defina el tipo de puerta y el lado de las bisagras. Luego ingrese las dimensiones deseadas. Todos los modelos están escalados al milímetro y, por tanto, encajan exactamente en cada módulo del sistema.",
          "TextBox2": "Genere todos los patrones de agujeros imaginables arrastrando y soltando los componentes prefabricados. El configurador presta atención directa a la verosimilitud, comprueba si hay colisiones y, si es necesario, las muestra. El gemelo digital del armario de control garantiza la funcionalidad y es la base para un diseño conforme a las normas.",
          "TextBox3": "Con un solo clic podrá decidir si su armario eléctrico es de chapa de acero o de acero inoxidable.  Elija libremente el color y la textura de la superficie según su gusto, el CI de su empresa o la solicitud de color de su cliente. Cada armario de control se fabrica directamente en el color deseado.",
          "TextBox4": "No solo puede diseñar, sino también realizar pedidos directamente en línea, las 24 horas del día, los 7 días de la semana, desde cualquier parte del mundo. Los datos se transmiten directamente a nuestra producción, lo que garantiza un tiempo de producción corto. Gracias al nuevo proceso de pedidos online B2B, también puede enviar su solicitud de pedido a un colega, p. B. adelante en la compra.",
          "TextHeader1": "Todas las carcasas están dimensionadas al milímetro y, por tanto, caben en cualquier hueco.",
          "TextHeader2": "Nunca más tendrás que editar un armario de control con un taladro.",
          "TextHeader3": "En cualquier caso te ahorras el proceso de repintado.",
          "TextHeader4": "10 días de tiempo de producción para una carcasa de armario de control individual es algo único en la industria.",
          "startConfigBtn": "Iniciar nueva configuración"
        },
        "Studies": {
          "ButtonText": "Lea el estudio de caso completo.",
          "CollapseHead1": "Situación inicial:",
          "CollapseHead2": "Desafío:",
          "CollapseHead3": "La solución:",
          "CollapseHead4": "A la larga:",
          "CollapseText1": "Hugo Beck Maschinenbau es especialista en máquinas de envasado horizontal flowpack, film y papel. En el pasado, Hugo Beck estaba acostumbrado a comprar armarios de control estándar y a perforar agujeros él mismo. Para evitar retrabajos que requieren mucho tiempo, los gabinetes internos estándar ya están fabricados. Desafortunadamente, los armarios estándar no son adecuados en todos los casos, incluso aunque estén repintados.",
          "CollapseText2": "El armario de control debe ser muy pequeño. Al mismo tiempo, la carcasa también debe ser muy profunda, ya que en el control se encuentra un convertidor de frecuencia. Casi no existen soluciones estándar para esto. Las desviaciones de las carcasas estándar internas siempre requieren un gran esfuerzo de coordinación y producción. Se buscó una solución que ahorrara tiempo y costes. Deben evitarse trabajos posteriores que requieren mucho tiempo. Un diseño especial del proveedor anterior requirió dibujos separados y un proceso de coordinación importante. Este proceso cuesta mucho tiempo y dinero. Después de muchos dibujos y experimentos, Hugo Beck conoció ARMARiO.",
          "CollapseText3": "Con el configurador online, Hugo Beck dispone ahora de una solución que permite a la empresa prescindir por completo de la construcción mecánica. El jefe de diseño eléctrico puede llevar a cabo todo el proceso de diseño por sí mismo. A continuación, los requisitos se transmiten al departamento de compras con todos los datos, como el número de artículo, las dimensiones, el diseño y el precio. Una muy buena solución para un electricista que necesita un armario con dimensiones especiales. Además se enumeran exactamente los componentes para definir los patrones de agujeros en el sistema que Hugo Beck necesita en su trabajo diario. Incluso si se requiere un patrón de agujeros exótico, éste se puede crear rápida y fácilmente usando las funciones de rectángulo y círculo. También es muy cómodo cambiar de acero a acero inoxidable. Un sistema flexible que Hugo Beck ofrece resultados muy rápidamente. Siempre que se requieren soluciones especiales, el configurador en línea es la herramienta preferida.",
          "CollapseText4": "El armario de control ARMARiO es un poco más caro que el armario de control interno estándar, pero no hay costes adicionales por reelaboración. Los ahorros son aún mayores con un armario de control especial, ya que también se eliminan los costes de planificación y construcción. No hay experimentos, ni diálogos largos e interdepartamentales ni sorpresas. En definitiva, una forma rápida de conseguir carcasas completamente personalizadas y con una calidad única. Con el proceso de pedidos B2B recientemente desarrollado, es decir, la posibilidad de compartir la configuración creada con el departamento de compras, ahora es realmente perfecto.",
          "CollapseText5": "FF Maschinen GmbH es una oficina de diseño para estudiantes. En el desarrollo de máquinas se ofrece todo el proceso, desde la concepción hasta el desarrollo del producto. La atención se centra en el ámbito de la construcción de máquinas especiales, incluidas las máquinas de embalaje.",
          "CollapseText6": "Durante el desarrollo, la atención se centra generalmente en el diseño mecánico de la máquina y sólo después en la planificación eléctrica. El desafío es determinar primero qué controles, accionamientos, etc. son necesarios. El espacio para los armarios de control suele estar ya planificado, pero normalmente no encaja exactamente. A menudo sólo es adecuado un armario eléctrico hecho a medida, especialmente debido a la profundidad de los convertidores. Los requisitos para un armario de control no suelen ser especialmente elevados, pero encontrar los recortes adecuados para interruptores, ventiladores, etc. suele ser un desafío. En el mejor de los casos, ya deberían estar terminados. En el pasado, el taladro tenía que utilizarse con demasiada frecuencia y de forma demasiado creativa.",
          "CollapseText7": "El configurador ARMARiO le guía de forma increíblemente sencilla a través de los pasos de procesamiento individuales. En tan solo unos minutos se puede crear un armario de control perfectamente adaptado. Se podrían integrar visualmente componentes como ventiladores y botones, creando así un patrón de agujeros individual. La visualización del armario de control se puede descargar e integrar en la planificación del sistema. Además de la construcción sencilla y rápida, también convenció el plazo de entrega de 10 días laborables.",
          "CollapseText8": "La empresa FF Maschinen GmbH utiliza actualmente ARMARiO con regularidad y lo recomienda con entusiasmo. Los diferentes pedidos requieren a menudo armarios de control individuales. La facilidad de uso y la rápida entrega hacen de ARMARiO un socio indispensable. La entrega rápida significa que se puede lograr una mayor flexibilidad en el procesamiento del proyecto.",
          "Header1": "ESTUDIOS DE CASOS.",
          "TextBox1": "„Esto es lo más fácil del mundo para mí. Tan fácil como comprar un contactor.“",
          "TextBox2": "„Cambiar los datos de la cuenta de mi contrato de móvil es más complicado que configurar un armario de control con ARMARiO.“",
          "TextBoxHeader1": "Hugo Beck Maschinenbau GmbH & Co. KG",
          "TextBoxHeader2": "FF Maschinenbau GmbH",
          "TextBoxSpan1": "Hugo Beck Maschinenbau GmbH & Co. KG, Dettingen, Gerhard Fink",
          "TextBoxSpan2": "FF Maschinen GmbH, Pforzheim, Felix Theurer"
        }
      }
    },
    "Helpers": {
      "OrderStatus": {
        "ANGEBOT": "Oferta",
        "BESTELLT": "Ordenado",
        "OFFEN": "Abierto"
      }
    },
    "OrderConfirmation": {
      "AGBS": {
        "AGB": "Lea los términos y condiciones",
        "CancellationInfo": "Leer instrucciones de cancelación"
      },
      "Button1": "DE ACUERDO",
      "Button2": "Ordene por una tarifa",
      "Button3": "Iniciar sesión",
      "FormGroup": {
        "Label1": "compañía",
        "Label10": "Armario de control creado por:",
        "Label11": "Fecha:",
        "Label12": "Introduzca aquí su número de pedido como referencia individual.",
        "Label2": "Código Postal",
        "Label3": "Ubicación",
        "Label4": "Calle + número de casa",
        "Label5": "Dirección de entrega",
        "Label6": "Sobrescribir la dirección de entrega",
        "Label7": "datos maestros",
        "Label8": "Número de cliente:",
        "Label9": "Número de oferta:",
        "Save": "ahorrar"
      },
      "Head1": "También fabricamos todos los armarios de control.",
      "Head2": "acero inoxidable",
      "Head3": "Dirección de Envio",
      "Head4": "Sobrescribir la dirección de facturación",
      "Head5": "orden",
      "Table": {
        "Head1": "Descripción del artículo",
        "Head10": "19,00% IVA",
        "Head11": "euros",
        "Head12": "Monto de la oferta:",
        "Head2": "Multitud",
        "Head3": "A MÍ",
        "Head4": "Precio euros",
        "Head5": "Quién euros",
        "Head6": "Designación:",
        "Head7": "Artículo:",
        "Head8": "Pedazo",
        "Head9": "Importe neto"
      }
    },
    "Steps": {
      "Bar": {
        "Button1": "Elige otro color",
        "Button2": "Continuar con Ral 7035 estructura gruesa, brillante",
        "Main1": "Armario",
        "Main2": "Componentes",
        "Main3": "Color / Material",
        "Main4": "la terminación",
        "Text1": "Aún no has elegido un color.",
        "Text2": "Por favor seleccione un color.",
        "Text3": "Iniciar sesión",
        "Text4": "Más"
      },
      "Colors": {
        "ColorHead": "Color",
        "CurrentColor": "Elección de color actual:",
        "DefaultColor": "Standard Farben",
        "ESDColor": "colores ESD",
        "LoadColor": "Los colores se están cargando.",
        "Main1": "Recubrimiento de color según RAL",
        "MaterialOption1": "chapa de acero",
        "MaterialOption2": "acero inoxidable",
        "RemoveColor": "Quitar pintura",
        "StructurOption1": "Suave brillante",
        "StructurOption2": "Estructura gruesa brillante",
        "SubHeader1": "material",
        "SubHeader2": "estructura",
        "Surface": "Superficie: rectificada por un lado K240"
      },
      "Components": {
        "CheckDeleteLayout1": "¿Quieres el diseño? \"",
        "CheckDeleteLayout2": "realmente eliminar?",
        "CheckDeleteLayout3": "No!",
        "CheckDeleteLayout4": "Sí",
        "Circle": "Círculo",
        "ComponentLayoutSave": "Guardar componentes como diseño",
        "ComponentName": "Componentes:",
        "ComponentsBar1": "Componentes",
        "ComponentsBar2": "Avanzado",
        "ComponentsBar3": "diseños",
        "Datasheet": "Ficha de datos",
        "Diameter": "diámetro:",
        "Distance": "Distancia",
        "ExpandedHead1": "patrón lineal",
        "Height": "Altura:",
        "Insert": "Insertar",
        "LayoutInfo": "Se permite un máximo de 20 componentes.",
        "LayoutName": "Nombre del diseño",
        "Length": "longitud:",
        "LoadLayout": "Cargar diseño",
        "Main1": "Su armario de control",
        "NewInfo": "NUEVO",
        "Number": "Número:",
        "Optional": "(Opcional)",
        "Rectangle": "rectángulo",
        "RequiredComponentText": "Seleccione al menos un componente.",
        "Rotation": "rotación:",
        "Rounding": "redondeo:",
        "Save": "Ahorrar",
        "SaveLayout": "Guardar diseño",
        "Size": "Tamaño:",
        "SubDepth": "profundidad:",
        "SubHeight": "Altura:",
        "SubWidth": "Ancho:",
        "Width": "Ancho:",
        "X": "X:",
        "Y": "Y:"
      },
      "ConfigurationsOverview": {
        "Head1": "¿Eliminar configuración?\"",
        "Table": {
          "Head1": "Borrar?",
          "Head10": "Comportamiento",
          "Head2": "Creado por",
          "Head3": "Status",
          "Head4": "estado",
          "Head5": "Número de configuración",
          "Head6": "Modelo",
          "Head7": "Ancho/Alto/Largo",
          "Head8": "material",
          "Head9": "Color",
          "Share": "Dividir",
          "View": "Vista"
        },
        "Text1": "¿Quieres tu configuración?",
        "Text2": "realmente eliminar?"
      },
      "Finish": {
        "AddressHead1": "Dirección de entrega diferente",
        "Button1": "Dirección de Envio",
        "Button2": "Dirección de entrega",
        "Button3": "Ordene por una tarifa",
        "Button4": "Copiar como plantilla",
        "Button5": "Ordene nuevamente",
        "Button6": "Descargar oferta",
        "Button7": "Reenviar para aprobación",
        "Button8": "Crear oferta",
        "Coating": "Revestimiento:",
        "ConfigurationNumber": "Número de configuración:",
        "Depth": "profundidad:",
        "DownloadHead1": "Oferta",
        "DownloadHead2": "crear",
        "DownloadHead3": "fue creado",
        "Errors": {
          "AGB": "Debes aceptar los términos y condiciones",
          "AmountError": "Por favor ingrese una cantidad",
          "ConfigurationError1": "La configuración no forma parte de su cuenta y no es de acceso público. Inicie sesión o cree una nueva configuración.",
          "ConfigurationError2": "Iniciado sesión con: ",
          "NameError": "Por favor ingresa un nombre interno",
          "Revocation": "Debes aceptar la política de cancelación."
        },
        "Head1": "Modelo seleccionado",
        "Head2": "Color/Material",
        "Head3": "Patrones de agujeros",
        "Head4": "Vistas",
        "Head5": "Orden",
        "Head6": "Ordene nuevamente",
        "Height": "Altura:",
        "InfoText1": "Pueden aplicarse costos adicionales",
        "InfoText2": "Para solicitar esta configuración o crear una cotización, debe",
        "InfoText3": "Iniciar sesión",
        "InfoText4": "Esta configuración ha sido ordenada.",
        "InfoText5": "Se ha creado una oferta para esta configuración.",
        "InfoText6": "y una liberación",
        "InfoText7": "enviado",
        "InputLabel1": "numero de piezas",
        "InputLabel2": "nota",
        "InputLabel3": "referencia",
        "InputLabel4": "Fecha de entrega deseada",
        "InputLabel5": "Lea los términos y condiciones",
        "InputLabel6": "Leer instrucciones de cancelación",
        "InputLabel7": "Número de oferta:",
        "Main1": "Consulta y completa tu pedido",
        "Material": "material:",
        "ModalButton1": "Ahorrar",
        "ModalButton2": "DE ACUERDO",
        "ModalHead1": "Orden de avance para aprobación",
        "ModalHead2": "Vista del armario de control",
        "ModalInfoText1": "componente",
        "ModalInfoText2": "Número",
        "ModalInfoText3": "No, gracias",
        "ModalInfoText4": "Solicitar una cotización",
        "ModalInfoText5": "DE ACUERDO",
        "ModalInfoText6": "¡Tu oferta ha sido creada!",
        "ModalInfoText7": "¿Debería crearse también una oferta para los componentes de su configuración?",
        "ModalInfoText8": "¡Tu oferta ha sido solicitada!",
        "ModalInfoText9": "¡Los componentes han sido solicitados y se le enviará una cotización en breve!",
        "ModalLabel1": "nombre",
        "ModalLabel2": "compañía",
        "ModalLabel3": "Código Postal",
        "ModalLabel4": "Ubicación",
        "ModalLabel5": "Calle + número de casa",
        "ModalLabel6": "Dirección de correo electrónico del destinatario",
        "ModalLabel7": "Contenido",
        "ModalLabel8": "Archivo adjunto adicional:",
        "ModalSuccessText1": "¿Debería crearse una oferta para los componentes de su configuración?",
        "NoCoating": "sin recubrimiento",
        "NoHolePictures": "Sin patrones de agujeros",
        "NoStructur": "Ninguna estructura elegida",
        "NoViews": "No hay vistas disponibles",
        "OrderHistory": "Historial de pedidos",
        "PaymentHead1": "Dirección de facturación diferente",
        "Placeholder": {
          "Text1": "Nota sobre el pedido..."
        },
        "Structur": "estructura:",
        "Surface": "superficie:",
        "SurfaceText": "suelo por un lado K240",
        "Width": "Ancho:"
      }
    }
  }], ["fr-fr", {
    "AccountModal": {
      "Change": "Changement",
      "Head1": "Modifier le compte",
      "Head2": "Modifier la photo de profil",
      "Label1": "entreprise",
      "Label2": "compte*",
      "Login": "Registre",
      "LogoutMessage": "Vous avez été déconnecté. Vous pouvez vous réinscrire ici :",
      "MainAcc": {
        "CreateAccount": "Créer un compte",
        "Error": "Erreur",
        "ForgotPassword": "Vous avez oublié votre mot de passe ?",
        "Head1": "Registre",
        "ResendPassword": "Nous vous avons envoyé un e-mail contenant des instructions sur la façon de réinitialiser votre mot de passe.",
        "Success": "Succès"
      },
      "Remove": "Retirer",
      "RequiredFields": "* Champs obligatoires",
      "Save": "Sauvegarder",
      "UploadImage": "Télécharger une image"
    },
    "BurgerMenu": {
      "AccountInformation": "Informations sur le compte",
      "Company": "Administration de l'entreprise",
      "DataProtection": "Protection des données",
      "HelpAndSupport": {
        "Chat": "Chat d'assistance",
        "FirewallSetting": "Paramètres du pare-feu",
        "Head1": "Aide et support",
        "Mail": "E-Mail: armario@fimab.eu",
        "Tel": "Téléphone d'assistance: 07053 96839-65"
      },
      "Imprint": "imprimer",
      "Legal": {
        "DataProtection": "Protection des données",
        "Header": "Légal",
        "Imprint": "imprimer"
      },
      "Login": "Registre",
      "Logout": "Se déconnecter",
      "OrderHistory": "Aperçu de la commande",
      "Support": {
        "FirewallSetting": "Paramètres du pare-feu ARMARIO pour les entreprises",
        "Head1": "Soutien",
        "SuppChat": "Chat d'assistance",
        "SuppMail1": "Adresse e-mail d'assistance",
        "SuppMail2": "armario@fimab.eu",
        "SuppTel1": "Numéro de téléphone d'assistance",
        "SuppTel2": "+49 (7053) 96839-65"
      },
      "UserProfil": "Profil utilisateur"
    },
    "Company": {
      "CompanyCard": {
        "Leave": "Partir"
      },
      "Employees": {
        "Header": "Employés"
      },
      "Header": "Votre entreprise",
      "OpenInvites": {
        "Filter": "Filtres d'état",
        "FilterOptions": {
          "Accepted": "Accepté",
          "Declined": "Rejeté",
          "Open": "Ouvrir"
        },
        "Header": "Invitations ouvertes"
      },
      "OpenRequests": {
        "Filter": "Filtres d'état",
        "FilterOptions": {
          "Accepted": "Accepté",
          "Declined": "Rejeté",
          "Open": "Ouvrir"
        },
        "Header": "Demandes ouvertes"
      },
      "Table": {
        "Accept": "Accepter",
        "Actions": "Actes",
        "Cancel": "Annuler",
        "Created": "Créé le",
        "Date": "Invité sur",
        "Decline": "Refuser",
        "Department": "Département",
        "EMail": "E-Mail",
        "Empty": "Aucune invitation ouverte",
        "EmptyEmployees": "Aucun employé disponible",
        "Name": "nom",
        "Origin": "Origine",
        "OriginInvite": "Invité",
        "OriginManual": "Demandé manuellement",
        "OriginSystem": "Détecté par le système",
        "Status": "statut",
        "StatusAccepted": "Accepté",
        "StatusDeclined": "Abgelehnt",
        "StatusWait": "En attente de confirmation"
      }
    },
    "Configurations": {
      "AutoSave": {
        "Text1": "Votre configuration sera enregistrée automatiquement",
        "Text2": "Connectez-vous pour activer la sauvegarde automatique"
      },
      "ModalSelection": {
        "Cabinet": {
          "Klemmkasten": "",
          "Modell 1": "(Porte simple)",
          "Modell 1: Anschlag Links": "",
          "Modell 1: Anschlag Rechts": "",
          "Modell 2": "(Double porte)",
          "Modell 3": "(Triple porte)"
        },
        "FooterText1": "Nous fabriquons également toutes les armoires de commande",
        "FooterTextSpan": "acier inoxydable",
        "Main1": "Entrez ici les dimensions de votre armoire de commande.",
        "Main2": "Choisissez votre type d'armoire de commande approprié",
        "SelectButton": "Choisir"
      },
      "ModelBox": {
        "Main1": "Modèle sélectionné"
      },
      "OptionBox": {
        "InfoText1": "Merci pour votre intérêt. Avec votre sélection, vous avez contribué au développement ultérieur de ces fonctionnalités !",
        "InfoText2": "(Les fonctionnalités mentionnées ci-dessus ne sont pas actuellement installées)",
        "Label1": "Toit de l'armoire de commande",
        "Label2": "Base d'armoire de commande",
        "Label2Height": "Hauteur du socle",
        "Label3": "Charnières internes",
        "Main1": "Possibilités",
        "OkButton": "Ok",
        "WarningBoxHeadline": "Achtung",
        "WarningBoxText1": "Merci pour votre intérêt. Avec votre sélection, vous avez contribué au développement ultérieur de ces fonctionnalités !",
        "WarningBoxText2": "Les fonctionnalités sélectionnées ne sont pas encore en cours d'installation."
      },
      "SelectedModal": {
        "Dimensions": "Dimensions de la plaque de montage",
        "DimensionsHeight": "Hauteur:",
        "DimensionsWidth": "Largeur:",
        "Label1": "Désignation interne",
        "Label2": "Largeur",
        "Label3": "Hauteur",
        "Label4": "profondeur",
        "Main1": "Dimensions",
        "NoMountingPlate": "Ce modèle ne comprend pas de plaque de montage"
      }
    },
    "Dashboard": {
      "Auth": {
        "ContinueConfiguration": {
          "ConfigColor": "Couleur:",
          "ConfigMaterial": "matériel:",
          "ConfigName": "nom:",
          "ContinueButton": "Continuer la configuration",
          "Header1": "Continuer la configuration",
          "Sub1": "Continuez à configurer votre dernière armoire configurée"
        },
        "LastConfigurations": {
          "ConfigAction": "Actes",
          "ConfigModel": "Modèle",
          "ConfigName1": "nom",
          "ConfigNr1": "Numéro de configuration.",
          "ConfigStatus": "statut",
          "ContinueButton": "Plus loin",
          "Header1": "Vos dernières configurations",
          "MineConfigsBtn": "Toutes les configurations",
          "ViewButton": "Voir"
        },
        "OrderConfiguration": {
          "Header1": "Armoire de commande",
          "Header2": "commande?",
          "OrderColor": "Couleur:",
          "OrderMaterial": "matériel:",
          "OrderName": "nom:",
          "Sub1": "Commandez votre dernière garde-robe configurée en quelques clics",
          "checkButton": "Vérifiez et commandez"
        },
        "Tipps": {
          "Header1": "Conseils du monde Armario",
          "Link1": "https://www.fimab.eu/schaltschrank-konfigurator/der-schnelle-online-bestellprozess",
          "Link2": "https://www.fimab.eu/schaltschrank-konfigurator/software-fuer-die-schaltschrank-konstruktion",
          "Link3": "https://www.fimab.eu/schaltschrank-konfigurator/edelstahl-ausfuehrungen-bei-allen-schaltschraenken",
          "Link4": "https://www.fimab.eu/schaltschrank-konfigurator/schluss-mit-verstaubten-schaltschraenken",
          "Link5": "https://www.fimab.eu/schaltschrank-konfigurator/schaltschraenke-mit-ableitender-beschichtung-esd",
          "Link6": "https://www.fimab.eu/schaltschrank-konfigurator/nie-wieder-loecher-in-schaltschraenke-bohren",
          "LinkHeader1": "Le processus de commande en ligne le plus rapide",
          "LinkHeader2": "Logiciel de conception pour armoires de commande",
          "LinkHeader3": "Versions en acier inoxydable pour toutes les armoires de commande",
          "LinkHeader4": "Fini les armoires de commande poussiéreuses",
          "LinkHeader5": "Armoires de commande avec peinture ESD",
          "LinkHeader6": "Ne percez plus jamais de trous dans les armoires de commande",
          "Tipp1": "Sélection du modèle",
          "Tipp2": "Concevoir des modèles de trous",
          "Tipp3": "Définir la couleur et la matière",
          "Tipp4": "Passer une commande"
        }
      },
      "Header": {
        "CTAAuth": "Démarrer une nouvelle configuration",
        "CTANoAuth": "Registre",
        "Main1": "EN LIGNE",
        "Main2": "ARMOIRE DE COMMANDE",
        "Main3": "CONFIGURATEUR.",
        "Sub": "ARMARIO SIGNIFIE VITESSE.",
        "Sub2": "DANS LA CONSTRUCTION D'ARMOIRES DE COMMANDE INDIVIDUELLES SUR MESURE.",
        "Text1": "Puis commande reçue. Votre avantage pour vous : L'armoire de commande est livrée entièrement selon vos spécifications, avec tous les trous et évidements. Cela vous évite des retouches fastidieuses et vous permet de commencer immédiatement à assembler les composants complémentaires prévus..",
        "Text2": "ARMARiO - Gagnez du temps et de l'argent !"
      },
      "NoAuth": {
        "Advantages": {
          "Header1": "SON",
          "Header2": "AVANTAGES",
          "Header3": "SUR",
          "Header4": "UN REGARD."
        },
        "FimabInfo": {
          "BtnText1": "Ndémarrez votre configuration",
          "Header1": "FiMAB SIGNIFIE COMPÉTENCE.",
          "Header2": "DANS LA PRODUCTION DE CLIENTS SATISFAITS.",
          "ListHeader1": "FiMAB - Nous allons vous inspirer !",
          "ListPoint1": "32 ans d'expérience en génie mécanique",
          "ListPoint2": "Entreprise familiale en 2ème génération",
          "ListPoint3": "Plus de 700 clients satisfaits en Allemagne et en Europe",
          "ListPoint4": "Plus de 500 variantes d'armoires de commande spécifiques au client livrées",
          "Main1": "CONFIGURER",
          "Main2": "ELLE",
          "Main3": "MAINTENANT",
          "Main4": "TON",
          "Main5": "ARMOIRE DE COMMANDE",
          "Main6": "SUR",
          "Main7": "ARMARIO",
          "Text1": "Vous le savez aussi : \"Hier, nous avons commandé 20 armoires de commande en stock et puis, en ce moment, le fournisseur de variateurs de fréquence change sa gamme de produits et désormais les appareils ne rentrent plus dans les armoires. Juste un montant à quatre chiffres pour le bac stocké.",
          "Text2": "Dans le processus de co-création, avec notre configurateur d'armoire électrique, vous, en tant qu'utilisateur, concevez votre produit exactement selon vos besoins. Commandez au bon moment et dans la quantité dont vous avez besoin maintenant. Grâce à la chaîne de processus interactive - vous contribuez à façonner le processus de création de valeur - et aux interfaces automatisées, nous combinons les avantages de deux mondes. Les avantages de la production de masse et ceux de la production individuelle. La personnalisation de masse signifie une production en série spécifique au client.",
          "Text3": "Chez FiMAB, nous avons plus de 30 ans d'expérience dans la production de lots de taille 1 pour la construction de machines spéciales. À partir de là, nous avons développé une chaîne de processus automatisée de la conception à la livraison. Nous produisons des lots de taille 1 en série à des prix réalistes. Pas de surproduction, pas de stockage, pas de gaspillage. Un tel processus interactif client-fournisseur est logique non seulement sur le plan économique mais aussi écologique.",
          "TextBreak": "Unser GeheiNotre conseil d’initié : suivez avec nous de nouveaux chemins et impressionnez vos collègues.mtipp für Sie: Gehen Sie mit uns neue Wege und beeindrucken Sie Ihre Kollegen."
        },
        "Information": {
          "InfoStep1": "Gain de temps",
          "InfoStep2": "Économies de coûts",
          "InfoStep3": "Taille optimale du passeport",
          "InfoStep4": "Nous fabriquons également toutes les armoires de commande",
          "InfoStep4Bold": "acier inoxydable",
          "TextBlock1": "Commander par",
          "TextBlock2": "16 heures",
          "TextBlock3": "et nous produisons votre armoire de commande par"
        },
        "ReadyToInstall": {
          "Header1": "PRÊT À INSTALLER",
          "Header2": "ARMOIRES DE COMMUTATION.",
          "StartConfigs": "Démarrer une nouvelle configuration",
          "Text1": "Félicitations, le fait que vous preniez le temps de lire cette brochure fait de vous un gagnant. Ces quelques minutes permettront à votre entreprise d'économiser des jours de planification et de retouche.",
          "Text2": "Nous avons élaboré cette brochure pour vous montrer comment obtenir l'armoire électrique parfaite en quelques minutes seulement. Cela vous fait gagner beaucoup de temps et vous offre la solution optimale pour votre machine d'emballage. En utilisant les résultats des clients, vous vivrez ce processus de manière réelle et compréhensible."
        },
        "Steps": {
          "Header1": "Variante et dimension",
          "Header2": "Modèles de trous",
          "Header3": "Matière et couleur",
          "Header4": "Passer une commande",
          "Main1": "SEULEMENT",
          "Main2": "4 ÉTAPES",
          "Main3": "AU PARFAIT",
          "Main4": "ARMOIRE DE COMMANDE.",
          "TextBox1": "Choisissez parmi les modèles une variante adaptée à votre armoire de commande ou à votre boîte à bornes. Définissez le type de porte et le côté des charnières. Entrez ensuite les dimensions souhaitées. Tous les modèles sont à l'échelle millimétrique et s'adaptent donc exactement à chaque module du système.",
          "TextBox2": "Générez tous les modèles de trous imaginables en faisant glisser et en déposant les composants préfabriqués. Le configurateur accorde une attention directe à la plausibilité, vérifie les collisions et les affiche si nécessaire. Le jumeau numérique de l'armoire de commande garantit la fonctionnalité et constitue la base d'une conception conforme aux normes.",
          "TextBox3": "Un simple clic décide si votre armoire de commande est en tôle d'acier ou en acier inoxydable.  Choisissez librement la couleur et la texture de la surface selon vos goûts, l'IC de votre entreprise ou la demande de couleur de votre client. Chaque armoire de commande est produite directement dans la couleur souhaitée.",
          "TextBox4": "Vous pouvez non seulement concevoir, mais aussi commander directement en ligne, 24h/24 et 7j/7, partout dans le monde. Les données sont transmises directement à notre production, ce qui garantit un délai de production court. Grâce au nouveau processus de commande en ligne B2B, vous pouvez également envoyer votre demande de commande à un collègue, par ex. B. avancer dans l'achat.",
          "TextHeader1": "Tous les boîtiers sont dimensionnés au millimètre près et s'adaptent donc à n'importe quel espace.",
          "TextHeader2": "Vous n'aurez plus jamais besoin de modifier une armoire de commande avec une perceuse.",
          "TextHeader3": "Dans tous les cas, vous vous épargnez le processus de repeinture.",
          "TextHeader4": "Un délai de production de 10 jours pour un boîtier d'armoire de commande individuel est unique dans l'industrie.",
          "startConfigBtn": "Démarrer une nouvelle configuration"
        },
        "Studies": {
          "ButtonText": "Lisez l’intégralité de l’étude de cas.",
          "CollapseHead1": "Situation initiale:",
          "CollapseHead2": "Défi:",
          "CollapseHead3": "La solution :",
          "CollapseHead4": "À long terme:",
          "CollapseText1": "Hugo Beck Maschinenbau est un spécialiste des machines d'emballage horizontales flowpack, film et papier. Dans le passé, Hugo Beck avait l'habitude d'acheter lui-même des armoires de commande standards et de percer des trous. Pour éviter des retouches fastidieuses, les armoires standards internes sont déjà fabriquées. Malheureusement, les armoires standards ne conviennent pas dans tous les cas, même si elles sont remises en état.",
          "CollapseText2": "L'armoire de commande doit être très petite. En même temps, le boîtier doit également être très profond car il y a un convertisseur de fréquence dans la commande. Il n’existe pratiquement aucune solution standard pour cela. Les écarts par rapport aux boîtiers standards internes nécessitent toujours beaucoup d'efforts de coordination et de production. Une solution permettant de gagner du temps et des coûts a été recherchée. Un travail de suivi fastidieux doit être évité. Une conception spéciale du fournisseur précédent nécessitait des dessins séparés et un processus de coordination majeur. Ce processus coûte beaucoup de temps et d’argent. Après de nombreux dessins et expérimentations, Hugo Beck a découvert l'ARMAriO.",
          "CollapseText3": "Avec le configurateur en ligne, Hugo Beck dispose désormais d'une solution qui permet à l'entreprise d'ignorer complètement la construction mécanique. Le responsable de la conception électrique peut réaliser lui-même l’ensemble du processus de conception. Les exigences sont ensuite transmises aux achats avec toutes les données telles que le numéro d'article, les dimensions, la conception et le prix. Une très belle solution pour un électricien qui a besoin d’une armoire aux dimensions particulières. De plus, sont répertoriés exactement les composants permettant de définir les modèles de trous dans le système dont Hugo Beck a besoin dans son travail quotidien. Même si un motif de trous exotique est requis, il peut être créé rapidement et facilement à l'aide des fonctions rectangle et cercle. Le simple fait de passer de l’acier à l’acier inoxydable est également très pratique. Un système flexible dont Hugo Beck donne des résultats très rapidement. Chaque fois que des solutions spéciales sont requises, le configurateur en ligne est l'outil privilégié.",
          "CollapseText4": "L'armoire de commande ARMARiO est légèrement plus chère que l'armoire de commande standard interne, mais il n'y a pas de frais supplémentaires pour les retouches. Les économies sont encore plus importantes avec une armoire de commande spéciale, car les coûts de planification et de construction sont également éliminés. Il n’y a pas d’expériences, pas de longs dialogues interdépartementaux et pas de surprises. En résumé, un moyen rapide d'obtenir des boîtiers entièrement personnalisés et d'une qualité unique. Avec le nouveau processus de commande B2B, c'est-à-dire la possibilité de partager la configuration créée avec le service des achats, il est désormais vraiment parfait.",
          "CollapseText5": "FF Maschinen GmbH est un bureau d'études étudiant. Lors du développement de machines, l'ensemble du processus est proposé, de la conception au développement du produit. L'accent est mis sur le domaine de la construction de machines spéciales, y compris les machines d'emballage.",
          "CollapseText6": "Lors du développement, l'accent est généralement mis sur la conception mécanique de la machine et ensuite seulement sur la planification électrique. Le défi consiste d’abord à déterminer quels contrôles, entraînements, etc. sont nécessaires. L'espace pour les armoires de commande est généralement déjà prévu, mais il n'est généralement pas adapté exactement. Souvent, seule une armoire de commande sur mesure convient, notamment en raison de la profondeur des convertisseurs. Les exigences posées à une armoire de commande ne sont généralement pas particulièrement élevées, mais trouver les découpes adéquates pour les interrupteurs, les ventilateurs, etc. constitue souvent un défi. Dans le meilleur des cas, ceux-ci devraient déjà être terminés. Dans le passé, il fallait utiliser la perceuse trop souvent et de manière trop créative.",
          "CollapseText7": "Le configurateur ARMARiO vous guide incroyablement facilement à travers les différentes étapes de traitement. Une armoire de commande parfaitement adaptée peut être créée en quelques minutes seulement. Des composants tels que des ventilateurs et des boutons pourraient être intégrés visuellement, créant ainsi un motif de trous individuel. La visualisation de l'armoire de commande peut être téléchargée et intégrée dans la planification du système. Outre la construction simple et rapide, le délai de livraison de 10 jours ouvrables était également impressionnant.",
          "CollapseText8": "L'entreprise FF Maschinen GmbH utilise désormais régulièrement l'ARMARiO et le recommande avec enthousiasme. Les différentes commandes nécessitent souvent des armoires de commande individuelles. La facilité d'utilisation et la livraison rapide font d'ARMARiO un partenaire indispensable. La livraison rapide signifie qu’une plus grande flexibilité peut être obtenue dans le traitement du projet.",
          "Header1": "ÉTUDES DE CAS.",
          "TextBox1": "„C'est pour moi la chose la plus simple au monde. Aussi simple que d'acheter un contacteur.“",
          "TextBox2": "„Changer les détails du compte de mon contrat de téléphonie mobile est plus compliqué que de configurer une armoire de commande avec ARMARiO.“",
          "TextBoxHeader1": "Hugo Beck Maschinenbau GmbH & Co. KG",
          "TextBoxHeader2": "FF Maschinenbau GmbH",
          "TextBoxSpan1": "Hugo Beck Maschinenbau GmbH & Co. KG, Dettingen, Gerhard Fink",
          "TextBoxSpan2": "FF Maschinen GmbH, Pforzheim, Felix Theurer"
        }
      }
    },
    "Helpers": {
      "OrderStatus": {
        "ANGEBOT": "Offre",
        "BESTELLT": "Ordonné",
        "OFFEN": "Ouvrir"
      }
    },
    "OrderConfirmation": {
      "AGBS": {
        "AGB": "Lire les termes et conditions",
        "CancellationInfo": "Lire les instructions d'annulation"
      },
      "Button1": "EN ORDRE",
      "Button2": "Commande payante",
      "Button3": "Registre",
      "FormGroup": {
        "Label1": "entreprise",
        "Label10": "Armoire de commande créée par:",
        "Label11": "Date:",
        "Label12": "Veuillez saisir ici votre numéro de commande comme référence individuelle.",
        "Label2": "Code Postal",
        "Label3": "Emplacement",
        "Label4": "Srue + numéro de maison",
        "Label5": "Lieferadresse",
        "Label6": "Remplacer l'adresse de livraison",
        "Label7": "Données de base",
        "Label8": "Numéro de client:",
        "Label9": "Numéro d'offre:",
        "Save": "sauvegarder"
      },
      "Head1": "Nous fabriquons également toutes les armoires de commande",
      "Head2": "acier inoxydable",
      "Head3": "Adresse de facturation",
      "Head4": "Remplacer l'adresse de facturation",
      "Head5": "commande",
      "Table": {
        "Head1": "Description de l'article",
        "Head10": "19,00% TVA",
        "Head11": "EUR",
        "Head12": "Montant de l'offre:",
        "Head2": "Foule",
        "Head3": "MOI",
        "Head4": "Prix EUR",
        "Head5": "Qui EUR",
        "Head6": "Désignation:",
        "Head7": "Article:",
        "Head8": "pièces",
        "Head9": "Montant net"
      }
    },
    "Steps": {
      "Bar": {
        "Button1": "Choisissez une autre couleur<",
        "Button2": "Continuer avec Ral 7035 structure grossière, brillant",
        "Main1": "Garde-robe",
        "Main2": "Composants",
        "Main3": "Couleur/Matériau",
        "Main4": "Diplôme",
        "Text1": "Vous n'avez pas encore choisi de couleur.",
        "Text2": "Veuillez sélectionner une couleur.",
        "Text3": "Registre",
        "Text4": "Plus loin"
      },
      "Colors": {
        "ColorHead": "Couleur",
        "CurrentColor": "Choix de couleur actuel :",
        "DefaultColor": "Standard Farben",
        "ESDColor": "Couleurs ESD",
        "LoadColor": "Les couleurs se chargent",
        "Main1": "Revêtement de couleur selon RAL",
        "MaterialOption1": "tôle d'acier",
        "MaterialOption2": "acier inoxydable",
        "RemoveColor": "Enlever la peinture",
        "StructurOption1": "Lisse et brillant",
        "StructurOption2": "Structure grossière brillante",
        "SubHeader1": "matériel",
        "SubHeader2": "structure",
        "Surface": "Surface : meulée d'un côté K240"
      },
      "Components": {
        "CheckDeleteLayout1": "Voulez-vous la mise en page? \"",
        "CheckDeleteLayout2": "vraiment supprimer ?",
        "CheckDeleteLayout3": "Non!",
        "CheckDeleteLayout4": "Oui",
        "Circle": "Cercle",
        "ComponentLayoutSave": "Enregistrer les composants sous forme de mise en page",
        "ComponentName": "Composants:",
        "ComponentsBar1": "Composants",
        "ComponentsBar2": "Avancé",
        "ComponentsBar3": "mises en page",
        "Datasheet": "Fiche de données",
        "Diameter": "diamètre:",
        "Distance": "Distance",
        "ExpandedHead1": "Motif linéaire",
        "Height": "Hauteur:",
        "Insert": "Insérer",
        "LayoutInfo": "Un maximum de 20 composants sont autorisés.",
        "LayoutName": "Nom de la mise en page",
        "Length": "longueur:",
        "LoadLayout": "Charger la disposition",
        "Main1": "Votre armoire de commande",
        "NewInfo": "NOUVEAU",
        "Number": "Nombre:",
        "Optional": "(Facultatif)",
        "Rectangle": "rectangle",
        "RequiredComponentText": "Veuillez sélectionner au moins un composant.",
        "Rotation": "rotation:",
        "Rounding": "arrondi:",
        "Save": "Sauvegarder",
        "SaveLayout": "Enregistrer la mise en page",
        "Size": "Taille:",
        "SubDepth": "profondeur:",
        "SubHeight": "Hauteur:",
        "SubWidth": "Largeur:",
        "Width": "Largeur:",
        "X": "X:",
        "Y": "Y:"
      },
      "ConfigurationsOverview": {
        "Head1": "Supprimer la configuration ?\"",
        "Table": {
          "Head1": "Supprimer?",
          "Head10": "Actes",
          "Head2": "Créé par",
          "Head3": "statut",
          "Head4": "nom",
          "Head5": "Configuration non",
          "Head6": "Modèle",
          "Head7": "Largeur/Hauteur/Longueur",
          "Head8": "matériel",
          "Head9": "Couleur",
          "Share": "Diviser",
          "View": "Voir"
        },
        "Text1": "Voulez-vous votre configuration?",
        "Text2": "vraiment supprimer ?"
      },
      "Finish": {
        "AddressHead1": "Adresse de livraison différente",
        "Button1": "Adresse de facturation",
        "Button2": "Adresse de livraison",
        "Button3": "Commande payante",
        "Button4": "Copier comme modèle",
        "Button5": "Commandez à nouveau",
        "Button6": "Télécharger l'offre",
        "Button7": "Transmettre pour approbation",
        "Button8": "Créer une offre",
        "Coating": "Revêtement:",
        "ConfigurationNumber": "Numéro de configuration:",
        "Depth": "profondeur:",
        "DownloadHead1": "Offre",
        "DownloadHead2": "créer",
        "DownloadHead3": "a été créé",
        "Errors": {
          "AGB": "Vous devez accepter les termes et conditions",
          "AmountError": "Veuillez entrer une quantité",
          "ConfigurationError1": "La configuration ne fait pas partie de votre compte et n'est pas accessible au public. Veuillez vous connecter ou créer une nouvelle configuration.",
          "ConfigurationError2": "Connecté avec: ",
          "NameError": "Veuillez saisir un nom interne",
          "Revocation": "Vous devez accepter la politique d'annulation"
        },
        "Head1": "Modèle sélectionné",
        "Head2": "Couleur/Matériau",
        "Head3": "Modèles de trous",
        "Head4": "Vues",
        "Head5": "Commande",
        "Head6": "Commandez à nouveau",
        "Height": "Hauteur:",
        "InfoText1": "Des frais supplémentaires peuvent s'appliquer",
        "InfoText2": "Pour commander cette configuration ou créer un devis, vous devez",
        "InfoText3": "Registre",
        "InfoText4": "Cette configuration a été commandée.",
        "InfoText5": "Une offre a été créée pour cette configuration",
        "InfoText6": "et une libération",
        "InfoText7": "envoyé",
        "InputLabel1": "Nombre de pièces",
        "InputLabel2": "note",
        "InputLabel3": "référence",
        "InputLabel4": "Date de livraison souhaitée",
        "InputLabel5": "Lire les termes et conditions",
        "InputLabel6": "Lire les instructions d'annulation",
        "InputLabel7": "Numéro d'offre:",
        "Main1": "Vérifiez et complétez votre commande",
        "Material": "matériel:",
        "ModalButton1": "Sauvegarder",
        "ModalButton2": "Ok",
        "ModalHead1": "Commande à terme pour approbation",
        "ModalHead2": "Vue de l'armoire de commande",
        "ModalInfoText1": "composant",
        "ModalInfoText2": "Nombre",
        "ModalInfoText3": "Non merci",
        "ModalInfoText4": "Demander un devis",
        "ModalInfoText5": "Okay",
        "ModalInfoText6": "Votre offre a été créée !",
        "ModalInfoText7": "Faut-il également créer une offre pour les composants de votre configuration ?",
        "ModalInfoText8": "Votre offre a été demandée !",
        "ModalInfoText9": "Les composants ont été demandés et un devis vous sera envoyé sous peu !",
        "ModalLabel1": "nom",
        "ModalLabel2": "entreprise",
        "ModalLabel3": "Code Postal",
        "ModalLabel4": "Emplacement",
        "ModalLabel5": "Rue + numéro de maison",
        "ModalLabel6": "Adresse email du destinataire",
        "ModalLabel7": "Contenu",
        "ModalLabel8": "Pièce jointe supplémentaire :",
        "ModalSuccessText1": "Faut-il créer une offre pour les composants de votre configuration ?",
        "NoCoating": "pas de revêtement",
        "NoHolePictures": "Aucun motif de trous",
        "NoStructur": "Aucune structure choisie",
        "NoViews": "Aucune vue disponible",
        "OrderHistory": "Historique des commandes",
        "PaymentHead1": "Adresse de facturation différente",
        "Placeholder": {
          "Text1": "Remarque sur la commande..."
        },
        "Structur": "Stuktur:",
        "Surface": "Surface:",
        "SurfaceText": "sol d'un côté K240",
        "Width": "Largeur:"
      }
    }
  }]];
  _exports.default = _default;
});