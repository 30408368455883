define("fimab-2d-view/types", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.DragModes = _exports.DragAxisRestriction = _exports.CubeSides = _exports.CabinetStructure = _exports.CabinetModels = _exports.CabinetMaterial = _exports.AlignmentTypes = _exports.ActionTypes = void 0;
  // Alle Enums aus dem Type Declaration File des Projekts (<root>/types/index.d.ts) werden hier
  // noch einmal aufgeführt, weil irgendwo in den TS Compiler Settings oder, wahrscheinlicher, im
  // Ember Buildprozess irgendwas im Arsch ist.
  var CabinetModels;
  _exports.CabinetModels = CabinetModels;
  (function (CabinetModels) {
    CabinetModels["MODEL_1_RIGHT"] = "model1_right";
    CabinetModels["MODEL_1_LEFT"] = "model1_left";
    CabinetModels["MODEL_2"] = "model_2";
    CabinetModels["MODEL_3"] = "model_3";
    CabinetModels["MODEL_4_LEFT"] = "model4_left";
    CabinetModels["MODEL_4_RIGHT"] = "model4_right";
  })(CabinetModels || (_exports.CabinetModels = CabinetModels = {}));
  var CubeSides;
  _exports.CubeSides = CubeSides;
  (function (CubeSides) {
    CubeSides["TOP"] = "OBEN";
    CubeSides["RIGHT"] = "RECHTS";
    CubeSides["FRONT"] = "VORNE";
    CubeSides["LEFT"] = "LINKS";
    CubeSides["BOTTOM"] = "UNTEN";
    CubeSides["BACK"] = "HINTEN";
    CubeSides["INNER"] = "MONTAGEPLATTE";
  })(CubeSides || (_exports.CubeSides = CubeSides = {}));
  var CabinetMaterial;
  _exports.CabinetMaterial = CabinetMaterial;
  (function (CabinetMaterial) {
    CabinetMaterial["STAHLBLECH"] = "stahlblech";
    CabinetMaterial["EDELSTAHL"] = "edelstahl";
  })(CabinetMaterial || (_exports.CabinetMaterial = CabinetMaterial = {}));
  var CabinetStructure;
  _exports.CabinetStructure = CabinetStructure;
  (function (CabinetStructure) {
    CabinetStructure["GLATTGLAENZEND"] = "Glatt gl\xE4nzend";
    CabinetStructure["GROBSTRUKTURGLAENZEND"] = "Grobstruktur gl\xE4nzend";
  })(CabinetStructure || (_exports.CabinetStructure = CabinetStructure = {}));
  var AlignmentTypes;
  _exports.AlignmentTypes = AlignmentTypes;
  (function (AlignmentTypes) {
    AlignmentTypes["Top"] = "top";
    AlignmentTypes["Right"] = "right";
    AlignmentTypes["Bottom"] = "bottom";
    AlignmentTypes["Left"] = "left";
    AlignmentTypes["HorizontalCenter"] = "horizontal-center";
    AlignmentTypes["VerticalCenter"] = "vertical-center";
  })(AlignmentTypes || (_exports.AlignmentTypes = AlignmentTypes = {}));
  var DragModes;
  _exports.DragModes = DragModes;
  (function (DragModes) {
    DragModes["SMOOTH"] = "smooth";
    DragModes["SNAP"] = "snap";
    DragModes["NONE"] = "none";
  })(DragModes || (_exports.DragModes = DragModes = {}));
  var ActionTypes;
  _exports.ActionTypes = ActionTypes;
  (function (ActionTypes) {
    ActionTypes["NONE"] = "none";
    ActionTypes["SELECT"] = "select";
    ActionTypes["DRAG"] = "drag";
    ActionTypes["RESIZE"] = "resize";
    ActionTypes["ALIGN"] = "align";
    ActionTypes["CREATEDRAG"] = "createdrag";
  })(ActionTypes || (_exports.ActionTypes = ActionTypes = {}));
  var DragAxisRestriction;
  _exports.DragAxisRestriction = DragAxisRestriction;
  (function (DragAxisRestriction) {
    DragAxisRestriction["HORIZONTAL"] = "horizontal";
    DragAxisRestriction["VERTICAL"] = "vertical";
  })(DragAxisRestriction || (_exports.DragAxisRestriction = DragAxisRestriction = {}));
});