function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
define("fimab-2d-view/cabinet", ["exports", "fimab-2d-view/math", "fimab-2d-view/component", "fimab-2d-view/types"], function (_exports, _math, _component, _types) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.sizeCabinetSvg = _exports.isInsideCabinetSurface = _exports.getTotalComponentInstanceCount = _exports.getChangedCabinetConfiguration = _exports.getCabinetSide = _exports.exportCabinet = _exports.drawCabinetSVG = void 0;
  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _createForOfIteratorHelper(o, allowArrayLike) { var it = typeof Symbol !== "undefined" && o[Symbol.iterator] || o["@@iterator"]; if (!it) { if (Array.isArray(o) || (it = _unsupportedIterableToArray(o)) || allowArrayLike && o && typeof o.length === "number") { if (it) o = it; var i = 0; var F = function F() {}; return { s: F, n: function n() { if (i >= o.length) return { done: true }; return { done: false, value: o[i++] }; }, e: function e(_e) { throw _e; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var normalCompletion = true, didErr = false, err; return { s: function s() { it = it.call(o); }, n: function n() { var step = it.next(); normalCompletion = step.done; return step; }, e: function e(_e2) { didErr = true; err = _e2; }, f: function f() { try { if (!normalCompletion && it.return != null) it.return(); } finally { if (didErr) throw err; } } }; }
  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
  /**
   * Liefert true, falls das übergebene Rect vollständig innerhalb der Schrankfläche ist. Funktion
   * berücksichtigt keine evtl. vorhandenen gesperrten Bereiche!
   *
   * @param surface
   * @param rect
   */
  var isInsideCabinetSurface = function isInsideCabinetSurface(surface, rect) {
    var isInside = (0, _math.isContained)(rect, {
      x: surface.minX,
      y: surface.minY,
      w: surface.maxX - surface.minX,
      h: surface.maxY - surface.minY,
      r: 0
    });
    if (surface.lockAreas) {
      var intersectsLockArea = false;
      var _iterator = _createForOfIteratorHelper(surface.lockAreas),
        _step;
      try {
        for (_iterator.s(); !(_step = _iterator.n()).done;) {
          var lockArea = _step.value;
          if ((0, _math.intersectRects)(rect, _objectSpread(_objectSpread({}, lockArea), {}, {
            r: 0
          }))) {
            intersectsLockArea = true;
            break;
          }
        }
      } catch (err) {
        _iterator.e(err);
      } finally {
        _iterator.f();
      }
      return isInside && !intersectsLockArea;
    } else {
      return isInside;
    }
  };

  /**
   * Liefert die Gesamtanzahl aller Komponenten auf allen Schrankseiten
   *
   * @param cabinet
   */
  _exports.isInsideCabinetSurface = isInsideCabinetSurface;
  var getTotalComponentInstanceCount = function getTotalComponentInstanceCount(cabinet) {
    var c = 0;
    cabinet.sides.forEach(function (side) {
      c += side.components.length;
    });
    return c;
  };

  /**
   * Liefert die aktuelle Schrankkonfiguration, die von der Konfigurator-App verarbeitet wird
   *
   * @param cabinet
   * @param side
   */
  _exports.getTotalComponentInstanceCount = getTotalComponentInstanceCount;
  var getChangedCabinetConfiguration = function getChangedCabinetConfiguration(cabinet, side) {
    return _objectSpread(_objectSpread({}, cabinet), {}, {
      sides: cabinet.sides.map(function (cabinetSide) {
        // [TBD] Handling der Montageplatte (0,0 bezieht sich auf 0,0 der)
        if (cabinetSide.type === side) {
          var _instances = cabinetSide.components.map(function (instance) {
            var i = _objectSpread({}, instance);
            delete i.element;
            delete i.contentElement;
            return i;
          });
          return _objectSpread(_objectSpread({}, cabinetSide), {}, {
            components: _instances
          });
        } else {
          return cabinetSide;
        }
      })
    });
  };

  /**
   * Liefert die angegebene Schrankseite des übergebenen Schranks
   *
   * @param cabinet
   * @param side
   */
  _exports.getChangedCabinetConfiguration = getChangedCabinetConfiguration;
  var getCabinetSide = function getCabinetSide(cabinet, side) {
    return cabinet.sides.find(function (config) {
      return config.type === side;
    });
  };

  /**
   * Errechnet die optimalen Dimensionen um die ausgewählte Schrankseite zu zeichnen.
   * Diese Funktion muss bei jedem Schrankseitenwechsel oder einem resize Event aufgerufen werden.
   * Der Offset, Breite, Höhe und Skalierungsfaktor des SVG-Containers werden in der
   * cabinetSurfaceRect Property gespeichert.
   *
   * @param side
   */
  _exports.getCabinetSide = getCabinetSide;
  var sizeCabinetSvg = function sizeCabinetSvg(surface, board) {
    var container = board.find('.fimab-board__container');
    var availWidth = container.innerWidth();
    var availHeight = container.innerHeight() * 0.98;
    var requiredWidth = surface.width;
    var requiredHeight = surface.height;
    var ratioWidth = availWidth / requiredWidth;
    var ratioHeight = availHeight / requiredHeight;
    var cabinetSurfaceElement = board.find('.fimab-board__cabinetSurface').parent();
    var ratio;
    var width;
    var height;
    if (ratioWidth < ratioHeight) {
      width = availWidth;
      height = requiredHeight * ratioWidth;
      ratio = ratioWidth;
    } else {
      width = requiredWidth * ratioHeight;
      height = availHeight;
      ratio = ratioHeight;
    }
    // console.log('availWidth', availWidth, 'availHeight', availHeight, 'requiredWidth', requiredWidth, 'requiredHeight', requiredHeight, 'finalW', width, 'finalHeight', height, 'ratio', ratio);
    cabinetSurfaceElement.outerHeight(height).outerWidth(width);
    var cOffset = cabinetSurfaceElement.offset();
    var rect = {
      x: cOffset.left /*  + parseInt(cabinetSurfaceElement.css('marginLeft'))*/,
      y: cOffset.top /*  + parseInt(cabinetSurfaceElement.css('marginTop')) */,
      w: width,
      h: height,
      ratio: ratio,
      r: 0
    };
    return rect;
  };

  /**
   * Zeichnet die ausgewählte Schrankoberfläche mit SVG im übergebenenen Element.
   *
   * @param cabinet
   * @param side
   * @param element
   */
  _exports.sizeCabinetSvg = sizeCabinetSvg;
  var drawCabinetSVG = function drawCabinetSVG(cabinet, side, element) {
    var surface = getCabinetSide(cabinet, side);
    if (!surface) {
      throw "Schrankseite ".concat(side, " nicht gefunden");
    }
    // Inhalte von SVG Container loeschen
    while (element.childNodes.length) {
      element.removeChild(element.childNodes[0]);
    }
    var w = surface.width;
    var h = surface.height;
    if (isNaN(surface.height) || surface.height < 0 || isNaN(surface.width) || surface.width < 0 || surface.minX < 0 || surface.maxX < 0 || surface.minY < 0 || surface.maxY < 0) {
      return;
    }

    // @ts-ignore
    var draw = SVG(element).viewbox(0, 0, w, h).size(w, h);

    // Schrank außen mit Sperrfläche

    draw.rect(w, h).addClass('fimab-svg__cabinetSurfaceLockArea');
    var lockRects = surface.lockAreas;

    // Freie Schrankoberfläche

    if (lockRects) {
      draw.rect(lockRects[0].x - surface.minX, surface.maxY - surface.minY).addClass('fimab-svg__availableSurface').move(surface.minX, surface.minY);
      var nextRect = lockRects[1];
      var index = 0;
      var _iterator2 = _createForOfIteratorHelper(lockRects),
        _step2;
      try {
        for (_iterator2.s(); !(_step2 = _iterator2.n()).done;) {
          var lockRect = _step2.value;
          nextRect = lockRects[index + 1];
          var moveX = lockRect.x + lockRect.w;
          var moveY = surface.minY;
          var width = nextRect ? nextRect.x - moveX : surface.maxX - lockRect.x - lockRect.w;
          var height = surface.maxY - surface.minY;
          draw.rect(width, height).addClass('fimab-svg__availableSurface').move(moveX, moveY);
          index++;
        }
        // draw.rect(lockRects.x - surface.minX, surface.maxY - surface.minY)
        //   .addClass('fimab-svg__availableSurface')
        //   .move(surface.minX, surface.minY);
        // draw.rect(surface.maxX - lockRects.x - lockRects.w, surface.maxY - surface.minY)
        //   .addClass('fimab-svg__availableSurface')
        //   .move(lockRects.x + lockRects.w, surface.minY);
      } catch (err) {
        _iterator2.e(err);
      } finally {
        _iterator2.f();
      }
    } else if (side == "OBEN") {
      draw.rect(surface.maxX - surface.minX, surface.maxY - surface.minY).addClass('fimab-svg__availableSurface').move(surface.minX, h - surface.maxY);
      draw.rect(w, 25) // Tür darstellen
      .addClass('fimab-svg__door').move(0, h - 25).fill('#7b7b7b');
    } else if (side == "UNTEN") {
      draw.rect(surface.maxX - surface.minX, surface.maxY - surface.minY).addClass('fimab-svg__availableSurface').move(surface.minX, h - surface.maxY);
      draw.rect(w, 25) // Tür darstellen
      .addClass('fimab-svg__door').move(0, 0).fill('#7b7b7b');
    } else {
      draw.rect(surface.maxX - surface.minX, surface.maxY - surface.minY).addClass('fimab-svg__availableSurface').move(surface.minX, surface.minY);
    }
    if (side == "LINKS") {
      draw.rect(25, h) // Tür darstellen
      .addClass('fimab-svg__door').move(w - 25, 0).fill('#7b7b7b');
    }
    if (side == "RECHTS") {
      draw.rect(25, h) // Tür darstellen
      .addClass('fimab-svg__door').move(0, 0).fill('#7b7b7b');
    }

    // Scharniere und Türgriff
    if (side === _types.CubeSides.FRONT) {
      var sh = 50;
      var hingeWidth = 10;
      var hingeStrokeAttrs = {
        width: hingeWidth,
        color: '#7b7b7b',
        linecap: 'round'
      };
      var handleStrokeAttrs = {
        width: 40,
        color: '#7b7b7b',
        linecap: 'round'
      };
      var holeThingamajongFill = {
        fill: '#7b7b7b'
      };
      if (cabinet.model === _types.CabinetModels.MODEL_1_RIGHT) {
        draw.line(40, h / 2 - 50, 40, h / 2 + 50).strokehandleStrokeAttrs;
        draw.line(w - hingeWidth, 10, w - hingeWidth, 10 + sh).stroke(hingeStrokeAttrs);
        draw.line(w - hingeWidth, h - sh - 10, w - hingeWidth, h - 10).stroke(hingeStrokeAttrs);
        draw.line(w - hingeWidth, h / 2 - sh / 2, w - hingeWidth, h / 2 + sh / 2).stroke(hingeStrokeAttrs);
      } else if (cabinet.model === _types.CabinetModels.MODEL_1_LEFT) {
        draw.line(surface.width - 40, h / 2 - 50, surface.width - 40, h / 2 + 50).stroke(handleStrokeAttrs);
        draw.line(hingeWidth, 10, hingeWidth, 10 + sh).stroke(hingeStrokeAttrs);
        draw.line(hingeWidth, h - sh - 10, hingeWidth, h - 10).stroke(hingeStrokeAttrs);
        draw.line(hingeWidth, h / 2 - sh / 2, hingeWidth, h / 2 + sh / 2).stroke(hingeStrokeAttrs);
      } else if (cabinet.model === _types.CabinetModels.MODEL_2 || cabinet.model === _types.CabinetModels.MODEL_4_LEFT || cabinet.model === _types.CabinetModels.MODEL_4_RIGHT) {
        draw.line(hingeWidth, 10, hingeWidth, 10 + sh).stroke(hingeStrokeAttrs);
        draw.line(hingeWidth, h - sh - 10, hingeWidth, h - 10).stroke(hingeStrokeAttrs);
        draw.line(w - hingeWidth, 10, w - hingeWidth, 10 + sh).stroke(hingeStrokeAttrs);
        draw.line(w - hingeWidth, h - sh - 10, w - hingeWidth, h - 10).stroke(hingeStrokeAttrs);
        var _iterator3 = _createForOfIteratorHelper(lockRects),
          _step3;
        try {
          for (_iterator3.s(); !(_step3 = _iterator3.n()).done;) {
            var _lockRect = _step3.value;
            var lockOffset = 35;
            var circleSize = 20;
            if (_lockRect.locks === "left") {
              draw.circle(circleSize).attr(holeThingamajongFill).move(_lockRect.x + lockOffset - circleSize, 40); // oberer Knopf
              draw.circle(circleSize).attr(holeThingamajongFill).move(_lockRect.x + lockOffset - circleSize, h - 60); // unterer Knopf
            } else {
              draw.circle(circleSize).attr(holeThingamajongFill).move(_lockRect.x + _lockRect.w - lockOffset, 40); // oberer Knopf
              draw.circle(circleSize).attr(holeThingamajongFill).move(_lockRect.x + _lockRect.w - lockOffset, h - 60); // unterer Knopf
            }
          }
        } catch (err) {
          _iterator3.e(err);
        } finally {
          _iterator3.f();
        }
      } else if (cabinet.model === _types.CabinetModels.MODEL_3) {
        draw.circle(10).attr(holeThingamajongFill).move(10, 10);
        draw.circle(10).attr(holeThingamajongFill).move(w - 10 - 10, 10);
        draw.circle(10).attr(holeThingamajongFill).move(w - 10 - 10, h - 10 - 10);
        draw.circle(10).attr(holeThingamajongFill).move(10, h - 10 - 10);
      }
    }
  };

  /**
   * Liefert die Schrankkonfiguration, die vom 3D-View verarbeitet wird.
   *
   * @param cabinet
   */
  _exports.drawCabinetSVG = drawCabinetSVG;
  var exportCabinet = function exportCabinet(cabinet) {
    var exportCabinet = _objectSpread(_objectSpread({}, cabinet), {}, {
      sides: cabinet.sides.map(function (cabinetSurface) {
        return _objectSpread(_objectSpread({}, cabinetSurface), {}, {
          components: cabinetSurface.components.map(function (component) {
            return (0, _component.transformComponentForExport)(component, cabinetSurface);
          })
        });
      })
    });
    return exportCabinet;
  };
  _exports.exportCabinet = exportCabinet;
});