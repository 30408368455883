function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
define("fimab-2d-view/component", ["exports", "fimab-2d-view/util", "fimab-2d-view/math"], function (_exports, _util, _math) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.transformComponentForExport = _exports.restrictYOnSurface = _exports.restrictXOnSurface = _exports.restrictW = _exports.restrictRounding = _exports.restrictR = _exports.restrictH = _exports.restrictDecimal = _exports.restrictComponentValue = _exports.restrictCenterYOnSurface = _exports.restrictCenterXOnSurface = _exports.newComponentId = _exports.exportComponent = void 0;
  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _createForOfIteratorHelper(o, allowArrayLike) { var it = typeof Symbol !== "undefined" && o[Symbol.iterator] || o["@@iterator"]; if (!it) { if (Array.isArray(o) || (it = _unsupportedIterableToArray(o)) || allowArrayLike && o && typeof o.length === "number") { if (it) o = it; var i = 0; var F = function F() {}; return { s: F, n: function n() { if (i >= o.length) return { done: true }; return { done: false, value: o[i++] }; }, e: function e(_e) { throw _e; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var normalCompletion = true, didErr = false, err; return { s: function s() { it = it.call(o); }, n: function n() { var step = it.next(); normalCompletion = step.done; return step; }, e: function e(_e2) { didErr = true; err = _e2; }, f: function f() { try { if (!normalCompletion && it.return != null) it.return(); } finally { if (didErr) throw err; } } }; }
  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
  /**
   * Erzeugt eine eindeutige Komponenten ID
   */
  var newComponentId = function newComponentId() {
    return String('c' + String(new Date().getTime()).substr(-5) + (Math.random() * 10000 >> 0));
  };

  /**
   * Liefert einen validen x-Wert der Komponente auf der angegebenen Schrankoberfläche zurück
   *
   * @param surface
   * @param value
   * @param instance
   */
  _exports.newComponentId = newComponentId;
  var restrictXOnSurface = function restrictXOnSurface(surface, value, instance) {
    var bBox = (0, _math.boundingBox)(instance);
    var rightPosX = value + bBox.right;
    var leftPosX = value - bBox.left;
    var centerPosX = value + bBox.right / 2;

    // Double door check for lockarea in middle
    if (surface.lockAreas) {
      var _iterator = _createForOfIteratorHelper(surface.lockAreas),
        _step;
      try {
        for (_iterator.s(); !(_step = _iterator.n()).done;) {
          var lockArea = _step.value;
          var startLockArea = lockArea.x;
          var endLockArea = lockArea.x + lockArea.w;
          var centerLockArea = lockArea.x + lockArea.w / 2;
          if (rightPosX > startLockArea && centerPosX < centerLockArea) {
            return startLockArea - bBox.right;
          } else if (leftPosX < endLockArea && centerPosX > centerLockArea) {
            return endLockArea + bBox.left;
          }
        }
      } catch (err) {
        _iterator.e(err);
      } finally {
        _iterator.f();
      }
    }
    if (value - bBox.left < surface.minX) {
      return surface.minX + bBox.left;
    } else if (value + bBox.right > surface.maxX) {
      return surface.maxX - bBox.right;
    } else {
      return value;
    }
  };
  _exports.restrictXOnSurface = restrictXOnSurface;
  var restrictCenterXOnSurface = function restrictCenterXOnSurface(surface, value, instance) {
    var bBox = (0, _math.boundingBox)(instance);
    var rightPosX = value + bBox.right;
    var leftPosX = value - bBox.left;
    var centerPosX = value + bBox.right / 2;

    // Double door check for lockarea in middle
    if (surface.lockAreas) {
      var _iterator2 = _createForOfIteratorHelper(surface.lockAreas),
        _step2;
      try {
        for (_iterator2.s(); !(_step2 = _iterator2.n()).done;) {
          var lockArea = _step2.value;
          var startLockArea = lockArea.x;
          var endLockArea = lockArea.x + lockArea.w;
          var centerLockArea = lockArea.x + instance.w / 2 + lockArea.w / 2;
          if (rightPosX > startLockArea && centerPosX < centerLockArea) {
            return startLockArea - bBox.right + instance.w / 2;
          } else if (leftPosX < endLockArea && centerPosX > centerLockArea) {
            return endLockArea + bBox.left + instance.w / 2;
          }
        }
      } catch (err) {
        _iterator2.e(err);
      } finally {
        _iterator2.f();
      }
    }
    if (value - bBox.left < surface.minX + instance.w / 2) {
      return surface.minX + bBox.left + instance.w / 2;
    } else if (value + bBox.right > surface.maxX + instance.w / 2) {
      return surface.maxX - bBox.right + instance.w / 2;
    } else {
      return value;
    }
  };

  /**
   * Liefert einen validen y-Wert der Komponente auf der angegebenen Schrankoberfläche zurück
   *
   * @param surface
   * @param value
   * @param instance
   */
  _exports.restrictCenterXOnSurface = restrictCenterXOnSurface;
  var restrictYOnSurface = function restrictYOnSurface(surface, value, instance) {
    var bBox = (0, _math.boundingBox)(instance);
    if (surface.minY > value - bBox.bottom) {
      return surface.minY + bBox.bottom;
    } else if (value + bBox.top > surface.maxY) {
      return surface.maxY - bBox.top;
    } else {
      return value;
    }
  };
  _exports.restrictYOnSurface = restrictYOnSurface;
  var restrictCenterYOnSurface = function restrictCenterYOnSurface(surface, value, instance) {
    var bBox = (0, _math.boundingBox)(instance);
    if (surface.minY + instance.h / 2 > value - bBox.bottom) {
      return surface.minY + bBox.bottom + instance.h / 2;
    } else if (value + bBox.top > surface.maxY + instance.h / 2) {
      return surface.maxY - bBox.top + instance.h / 2;
    } else {
      return value;
    }
  };
  _exports.restrictCenterYOnSurface = restrictCenterYOnSurface;
  var restrictR = function restrictR(value) {
    return value;
  };

  /**
   * Liefert eine valide Dezimalstelle auf 1 gerundet
   *
   * @param value
   * @param instance
   */
  _exports.restrictR = restrictR;
  var restrictDecimal = function restrictDecimal(value) {
    if (isNaN(value)) {
      // return 0;
    }
    if (typeof value !== 'string') {
      value = value.toString();
    }
    value = value.replace(",", ".");
    if (value.indexOf(".") > 0) {
      var _temp = value.split(".");
      var dec = _temp[1].substring(0, 2);
      value = _temp[0] + "." + dec;
    }

    // return Math.round((input.replace(',', '.') * 100 ) /100);
    return parseInt(value);
  };

  /**
   * Liefert eine valide Breite der Komponente zurück
   *
   * @param value
   * @param instance
   */
  _exports.restrictDecimal = restrictDecimal;
  var restrictW = function restrictW(value, instance) {
    if (instance.minW && value < instance.minW) {
      return instance.minW;
    } else if (instance.maxW && value > instance.maxW) {
      return instance.maxW;
    } else {
      return value;
    }
  };

  /**
   * Liefert eine valide Höhe der Komponente zurück
   *
   * @param value
   * @param instance
   */
  _exports.restrictW = restrictW;
  var restrictH = function restrictH(value, instance) {
    if (instance.minH && value < instance.minH) {
      return instance.minH;
    } else if (instance.maxH && value > instance.maxH) {
      return instance.maxH;
    } else {
      return value;
    }
  };

  /**
   * Liefert einen validen Wert für die Rundung der Komponente zurück
   *
   * @param value
   * @param instance
   */
  _exports.restrictH = restrictH;
  var restrictRounding = function restrictRounding(surface, value, instance) {
    var bBox = (0, _math.boundingBox)(instance);
    if (value < instance.minRounding || 0) {
      return instance.minRounding || 0;
    } else if (instance.maxRounding && value > instance.maxRounding) {
      return instance.maxRounding;
    } else {
      return value;
    }
  };

  /**
   * Liefert für die übergebene Property der Komponente einen erlaubten Wert zurück
   *
   * @param cabinetSurface
   * @param instance
   * @param key
   * @param value
   */
  _exports.restrictRounding = restrictRounding;
  var restrictComponentValue = function restrictComponentValue(cabinetSurface, instance, key, value) {
    var newValue = (0, _util.sanitizeUserInput)(value, instance[key]);
    if (key === 'x') {
      newValue = restrictXOnSurface(cabinetSurface, newValue, instance);
      newValue = (0, _math.round)(newValue);
    } else if (key === 'y') {
      newValue = restrictYOnSurface(cabinetSurface, newValue, instance);
      newValue = (0, _math.round)(newValue);
    } else if (key === 'r') {
      newValue = restrictR(newValue);
    } else if (key === 'w') {
      newValue = restrictW(newValue, instance);
      newValue = restrictDecimal(newValue);
    } else if (key === 'h') {
      newValue = restrictH(newValue, instance);
      newValue = restrictDecimal(newValue);
    } else if (key === 'rounding') {
      newValue = restrictRounding(cabinetSurface, newValue, instance);
      // newValue = restrictDecimal(newValue);
    } else if (key === 'centerX') {
      newValue = restrictCenterXOnSurface(cabinetSurface, newValue, instance);
      newValue = (0, _math.round)(newValue);
    } else if (key === 'centerY') {
      newValue = restrictCenterYOnSurface(cabinetSurface, newValue, instance);
      newValue = (0, _math.round)(newValue);
    }
    return newValue;
  };
  _exports.restrictComponentValue = restrictComponentValue;
  var transformComponentForExport = function transformComponentForExport(component, surface) {
    return exportComponent(component, surface);
  };
  _exports.transformComponentForExport = transformComponentForExport;
  var exportComponent = function exportComponent(component, surface) {
    return _objectSpread(_objectSpread({}, component), {}, {
      x: component.centerX,
      y: component.centerY,
      d: 40
    });
  };
  _exports.exportComponent = exportComponent;
});